import { setCookie } from 'cookies-next'; 
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "@/interFace/interFace";
import en from "../../locales/en.json"
import ar from '../../locales/ar.json';

interface userState {
  language: string;
  translations: any;
  user: any;
}

const initialState: userState = {
  language: 'en',
  translations: en,
  user: null
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        changeLanguage: (state, { payload }: PayloadAction<string>) => {
            state.language = payload;
            state.translations = payload === 'ar' ? ar : en;
        },
        setUser: (state, { payload }: PayloadAction<User | null>) => {
            state.user = payload;
            setCookie('isAuthenticated', (payload ? 'true' : 'false'));
        }
    },
});

export const { changeLanguage, setUser } = userSlice.actions;

export default userSlice.reducer;
