import { Link } from 'react-router-dom';
import React from "react";

interface paginationType {
  Pagination_space: string;
  totalPages: number;
  currentPage: number;
  setPage: any;
}

const Pagination = ({
  Pagination_space,
  totalPages,
  currentPage,
  setPage,
}: paginationType) => {
  const paginationItems: any = [];

  const startPage = currentPage < 4 ? 1 : currentPage - 2;
  const endPage = startPage + 4 > totalPages ? totalPages : startPage + 4 ; 

  // Loop through totalPages and generate pagination elements
  if (currentPage > 1)
  {
      paginationItems.push(
        <li
          key={-1}
        >
          <Link to="" onClick={() => setPage(1)}>
            {'<<'}
          </Link>
        </li>
      );
      paginationItems.push(
        <li
          key={0}
        >
          <Link to="" onClick={() => setPage(currentPage - 1)}>
            {'<'}
          </Link>
        </li>
      );
  }
  for (let pageNumber = startPage; pageNumber <= endPage; pageNumber++) {
    paginationItems.push(
      <li
        className={pageNumber === currentPage ? "active" : ""}
        key={pageNumber}
      >
        <Link to="" onClick={() => setPage(pageNumber)}>
          {pageNumber < 10 ? "0" + pageNumber : pageNumber}
        </Link>
      </li>
    );
  }
  if (currentPage < totalPages)
  {
    paginationItems.push(
      <li
        key={endPage + 1}
      >
        <Link to="" onClick={() => setPage(currentPage + 1)}>
          {'>'}
        </Link>
      </li>
    );
    paginationItems.push(
      <li
        key={endPage + 1}
      >
        <Link to="" onClick={() => setPage(totalPages)}>
          {'>>'}
        </Link>
      </li>
    );
  }
  return (
    <div
      className={`bd-basic__pagination ${
        Pagination_space ? Pagination_space : "mt-30 mb-20"
      }`}
      data-wow-delay=".3s"
    >
      <nav>
        <ul>{paginationItems}</ul>
      </nav>
    </div>
  );
};

export default Pagination;
