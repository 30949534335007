"use client";
import React, { useEffect } from "react";

import ProfileSidebar from "./ProfileSidebar";
import DashboardCounter from "./DashboardCounter";
import MyProfile from "./MyProfile";
import OrderHistory from "./OrderHistory";
import UpdateProfile from "./UpdateProfile";
import UserReviews from "./UserReviews";
import UserComments from "./UserComments";
import PaymentInfo from "./PaymentInfo";
import MyWishlist from "./MyWishlist";
import { useParams } from "react-router-dom";
import OrderDetails from "./OrderDetails";
import UserAddress from "./UserAddress";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const ProfileSection = () => {
  const params = useParams();
  
  const user = useSelector(
    (state: RootState) => state.user.user
  );

  const translations = useSelector((state: RootState) => state.user.translations);

  const renderContent = () => {
    switch (params.slug) {
      case 'Dashboard':
        return <DashboardCounter />;
      case 'Profile':
        return <MyProfile />;
      case 'Orders':
        return <OrderHistory />;
      case 'OrderDetails':
        return <OrderDetails />;
      case 'Payments':
        return <PaymentInfo />;
      case 'UserAddress':
        return <UserAddress />;
      case 'Reviews':
        return <UserReviews />;
      case 'Comments':
        return <UserComments />;
      case 'Settings':
        return <UpdateProfile />;
      case 'Wishlist':
        return <MyWishlist />;
      default:
        return <DashboardCounter />;
    }
  };

  return (
    <div className="course-details-area pt-30 pb-100">
      <div className="container">
        <div className="student-profile-author pb-30">
          <div>
              <img
                src={require("../../../assets/img/icon/user-icon.png")}
                style={{ width: "50px !important", height: "50px !important" }}
                alt="img not found"
              />
          </div>
          <div className="student-profile-author-text">
            <span>{translations?.nav?.hello},</span>
            <h3 className="student-profile-author-name text-capitalize"> {user?.first_name} {user?.father_name} {user?.last_name} </h3>
          </div>
        </div>
        <div className="row">
          <ProfileSidebar slug={params.slug as string} />
          <div className="col-xl-9 col-lg-8">
            <div className="student-profile-content">
              <h4 className="mb-25">
                {params.slug === "Profile" ? "My Profile" : params.slug}
              </h4>
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSection;
