import React from "react";
import LoginForm from "../../form/login-form";
const LoginMain = () => {
  return (
    <>
      <LoginForm />
    </>
  );
};

export default LoginMain;
