import React from 'react';
import ShopSection from './ShopSection';

const ShopMain = () => {
    return (
        <>
            <ShopSection/>
        </>
    ); 
};

export default ShopMain;