"use client";
import React , {useState,useEffect} from "react";
import Pagination from "./Pagination";
import { getBalancesList } from "../../../utils/ApiCalls";
import { constants } from "../../../utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {Helmet} from "react-helmet";


const PaymentInfo = () => {

  const [balances, setbalances] = useState<{id: number; amount: string, created_at: string; type: string; move_type: string }[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 10;
  const [totalPages, setTotalPages] = useState(1);
  const [currentBalance, setcurrentBalance] = useState(0);
  const translations = useSelector((state: RootState) => state.user.translations);

  useEffect(() => {
      get_wishlist(currentPage);
  }, [currentPage]);

  const get_wishlist = (page: number) => {
    setbalances([])
    getBalancesList(page, resultsPerPage).then(response => {
        setTotalPages(response.data.total)
        setcurrentBalance(response.data.balance)
        response.data.data.forEach((elem: any) => {
          setbalances(prev => [...prev, {
                id:elem.id,
                amount: elem.move_type  == 1 ? elem.move_in :elem.move_out ,
                created_at: formatDate(elem.created_at),
                type: elem.type.en_name ,
                move_type:elem.move_type,
            }]);
        });
    }).catch(error => {
        console.error("Error fetching wishlist:", error);
    });
};

const handlePageChange = (page: number) => {
    setCurrentPage(page);
}
const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}`;
};
  
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{`${translations?.nav?.website} - ${translations?.nav?.mypayment}`}</title>
    </Helmet>
      <div className="container mt-5">
        <h4> {translations?.nav?.balance} : {currentBalance.toLocaleString()} {constants.currency}</h4>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>{translations?.nav?.amount}</th>
              <th>{translations?.nav?.status}</th>
              <th>{translations?.nav?.type}</th>
              <th>{translations?.nav?.time}</th>
            </tr>
          </thead>
          <tbody>
            {balances.length ? (
              balances.map(balance => (
                <tr key={balance.id}>
                  <td>{balance.amount} {constants.currency}</td>
                  <td>{balance.type}</td>
                  <td>{balance.move_type == "1" ? <p className="badge bg-success">{translations?.nav?.in}</p> : <p className=" badge bg-warning">{translations?.nav?.out}</p>  }</td>
                  <td>{balance.created_at}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className="text-center">{translations?.nav?.nopayment}</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="row justify-content-center">
          <div className="col-xxl-12">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>

    </>
  );
};

export default PaymentInfo;
