"use client";
import React, { useState, useEffect } from "react";
import { getOrdersList } from "../../../utils/ApiCalls";
import Pagination from "./Pagination";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {Helmet} from "react-helmet";


const OrderHistory: React.FC = () => {
  const [orders, setOrders] = useState<{ id: string, total: number, created_at: string, items: any[] }[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 10;
  const [totalPages, setTotalPages] = useState(1);

    const translations = useSelector((state: RootState) => state.user.translations);


  useEffect(() => {
    getOrders(currentPage);
  }, [currentPage]);

  const getOrders = (page: number) => {
    setOrders([]);
    getOrdersList(page, resultsPerPage)
      .then(response => {
        setTotalPages(response.data.total);
        setOrders(response.data.data.map((elem: any) => ({
          id: elem.id,
          total: elem.total,
          created_at: formatDate(elem.created_at),
          items: elem.items
        })));
      })
      .catch(error => {
        console.error("Error fetching orders:", error);
      });
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}`;
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{`${translations?.nav?.website} - ${translations?.nav?.myorder}`}</title>
    </Helmet>
      <div className="container mt-5">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>{translations?.nav?.orderid}</th>
              <th>{translations?.nav?.total}</th>
              <th>{translations?.nav?.date}</th>
              <th>{translations?.nav?.quntity}</th>
              <th> {translations?.nav?.detailed}</th>
            </tr>
          </thead>
          <tbody>
            {orders.length ? (
              orders.map(order => (
                <tr key={order.id}>
                  <td>{order.id}</td>
                  <td>${order.total.toFixed(2)}</td>
                  <td>{order.created_at}</td>
                  <td>{order.items.length}</td>
                  <td>
                    <Link to={`/profile/OrderDetails/${order.id}`} style={{color:"green",cursor:"pointer"}}>
                    {translations?.nav?.viewdetailed}
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className="text-center">{translations?.nav?.noorder}</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="row justify-content-center">
          <div className="col-xxl-12">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderHistory;
