"use client";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { handle_clear_cart } from "../../redux/slices/cartSlice";
import { constants } from "../../utils/constants";
import { getAddressList, addOrder } from "../../utils/ApiCalls";
import { toast } from "react-toastify";
import OrderSuccess from './OrderSuccess';
import OrderFailed from './OrderFailed';
import {Helmet} from "react-helmet";

interface FormData {
  Country: string;
  Fname: string;
  Lname: string;
  Address: string;
  City: string;
  Postcode: string;
  EmailAddress: string;
  Phone: string;
}

const CheckOutMain = () => {
  const navigate = useNavigate();
  const [addressId, setaddressId] = useState(0);
  const [address, setAddress] = useState([]);
  const [orderResult, setOrderResult] = useState("");
  const dispatch = useDispatch();
  const cartProducts = useSelector(
    (state: RootState) => state.cart.cartProducts
  );

  const totalPrice = useSelector(
    (state: RootState) => state.cart.total
  );
  const language: any = useSelector(
    (state: RootState) => state.user.language
  );
  const user: any = useSelector(
    (state: RootState) => state.user.user
  );
  
  useEffect(() => {
    if(user) get_address();
    else setAddress([]);
  });


  const get_address = () => {
    getAddressList()
      .then((response) => {
          setAddress(response.data.data);
      })
      .catch((error) => {
          console.error("Error fetching addresses:", error);
      });
  };

  const hanleLogin = () =>{
    navigate("/login")
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if(addressId <= 0)
    {
      toast.error('Please Select your desired address');
      return false;
    }
    const result: any = await addOrder({ address_id: addressId })
    if(!result.data?.success)
    {
        setOrderResult('ERROR')
        toast.error(result?.response?.data?.message)
    }else 
    { 
      setOrderResult('SUCCESS');
      setTimeout(function() {
        window.location.href = '/';
      },3000)
      //dispatch(handle_clear_cart())
    }
        
  };
  const translations = useSelector((state: RootState) => state.user.translations);

  const selectHandler = () => {};

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{`${translations?.nav?.website} - ${translations?.nav?.checkout}`}</title>
    </Helmet>
      <section className="checkout-area pt-115 pb-100">
        <div className="container small-container">
          {
            !user && (
                <div className="coupon-accordion">
                    <h3>
                        {translations?.nav?.returning_customer}
                        {" "}
                        <span id="showlogin" onClick={hanleLogin}>
                        {translations?.nav?.click_hear_to_login}
                        </span>
                    </h3>
                    <div id="checkout-login" className={`coupon-content d-block`}>
                        <div className="coupon-info">
                            <p className="coupon-text">
                            </p>
                            {/* login form will show if user not login - login component is ready check folders  */}
                        </div>
                    </div>
                </div>
            )
          }
          {
            
             orderResult == 'SUCCESS' ? 
            (
              <OrderSuccess />
            )
            : orderResult == 'ERROR' ?
            (
              <OrderFailed />
            )
            : ''
          }
          {
            !orderResult && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  {/* billing info */}
                  <div className="col-lg-6">
                    <div className="checkbox-form">
                      <h3>{translations?.nav?.choose_ur_address}</h3>
                      <div className="row">
                          {address.map((item: any) => (
                            <div key={item.id} className="col-md-12">
                              <div className="card mb-4" style={{ borderColor: "#4CAF50" }}>
                                <div className="card-body d-flex align-items-start">
                                  <div style={( language == 'en' ? { marginRight : "20px" } : { marginLeft: "20px"} )  }>
                                      <input
                                        className="radio-box"
                                        type="radio"
                                        name="chosen-address"
                                        onChange={ () => {
                                          setaddressId(item.id)
                                        }}
                                        id={item.id}
                                      />
                                  </div>
                                  <div>
                                    <p className="card-text" style={{ color: "#555" }}>
                                      {item.city?.translations[language]?.name}, {item.area}, {item.street}, {item.building}, {translations?.nav?.floor} {item.floor}, 
                                    </p>
                                    <p className="card-text" style={{ color: "#555" }}>
                                      <strong>{translations?.nav?.phone}:</strong> {item.phone}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="col-md-12">
                                <Link to="/profile/UserAddress?redirect=/checkout" className="bd-fill__btn w-100">
                                {translations?.nav?.add_address}
                                </Link>
                          </div>
                      </div>
                    </div>
                  </div>
    
                  {/* order info */}
                  <div className="col-lg-6">
                    <div className="your-order mb-30 ">
                      <h3>{translations?.nav?.your_order}</h3>
                      <div className="your-order-table table-responsive">
                        <table>
                          <thead>
                            <tr>
                              <th className="product-name">{translations?.nav?.product}</th>
                              <th className="product-total">{translations?.nav?.total}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cartProducts.map((item, index) => {
                              return (
                                <tr className="cart_item" key={index}>
                                  <td className="product-name">
                                    {item?.translations[language]?.name}{" "}
                                    <strong className="product-quantity">
                                      {" "}
                                      × {item?.quantity}
                                    </strong>
                                  </td>
                                  <td className="product-total">
                                    <span className="amount">{item.price} {constants.currency}</span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          <tfoot>
                            <tr className="order-total">
                              <th>{translations?.nav?.order_total}</th>
                              <td>
                                <strong>
                                  <span className="amount">{totalPrice} {constants.currency}</span>
                                </strong>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
    
                      <div className="payment-method">
                        <div className="order-button-payment mt-20">
                          {cartProducts.length ? (
                            <>
                              <button type="submit" className="bd-fill__btn">
                              {translations?.nav?.place_order}
                              </button>
                            </>
                          ) : (
                            <>
                              <Link
                                to='/shop'
                                className="bd-fill__btn w-100"
                              >
                                {translations?.nav?.phone}Add Product For Checkout
                              </Link>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )
          }
        </div>
      </section>
    </>
  );
};

export default CheckOutMain;
