import React from 'react';
import HeroSectionThree from './HeroSectionThree';
import CategorySlider from './CategorySlider';
import TrendingProducts from './TrendingProducts';
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {Helmet} from "react-helmet";

const HomeThreeMain = () => {
    const translations = useSelector(
      (state: RootState) => state.user.translations
    );
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${translations?.nav?.website} - ${translations?.nav?.home}`}</title>
            </Helmet>
            <HeroSectionThree/>
            <CategorySlider/>
            <TrendingProducts/>
           {/* 
            */}
            {/* <NewsletterSection/> */}
            {/*<BlogSection/>*/}
        </>
    );
};

export default HomeThreeMain;