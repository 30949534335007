import React from "react";

const Preloader = () => {
  return (
    <>
      <div id="preloader">
        <div className="preloader">
          <span></span>
          <span></span>
        </div>
      </div>
    </>
  );
};

export default Preloader;
