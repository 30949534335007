"use client";
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { A11y, Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { cart_product } from "../../../redux/slices/cartSlice";
import useGlobalContext from "../../../hooks/use-context";
import { wishlist_product } from "../../../redux/slices/wishlistSlice";
import ProductModal from "../../../components/shop/ProductModal";
import { getFeaturedProductsList } from '../../../utils/ApiCalls';
import { useAppDispatch } from '../../../hooks/store-hooks';
import { constants } from "../../../utils/constants";
import { toast } from "react-toastify";

const TrendingProductSlider = () => {

  const translations = useSelector((state: RootState) => state.user.translations);

  const { setOpenModal, setModalId, openModal } = useGlobalContext();
  const [swiperKey, setSwiperKey] = useState(0);
  const dispatch = useAppDispatch();
  const language = useSelector(
    (state: RootState) => state.user.language
  );
  const user = useSelector(
    (state: RootState) => state.user.user
  );
  const [products, setProducts] = useState<[]>([]);

  useEffect(() => {
    getFeaturedProductsList().then(response => {
      setProducts(response.data.data)
    }).catch(error => {
      console.log(error)
    });
  }, []);

  useEffect(() => {
    if(swiperKey >= 0) setSwiperKey((prevKey) => prevKey + 1);
  }, [language]);

  const handleMoldalData = (id: string) => {
    if (id) {
      setOpenModal(!openModal);
      setModalId(id);
    }
  };

  const add_to_cart = (item: any) => {
    if(user) dispatch(cart_product(item))
    else toast.error('Please Login First !')
  }
  const handleWishlistProduct = (item: any) => {
    if(user) dispatch(wishlist_product(item))
    else toast.error('Please Login First !')
  }

  return (
    <>
      <div className="row">
        <div className="col-xxl-4 col-xl-5 col-lg-4">
          <div className="bd-section__title-wrapper mb-40">
            <div className="bd-sm__section-title">
              <h3>
              {translations?.nav?.Trending  || "Trending Product"}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="bd-trending__item-wrapper">
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active">
            <div className="bd-trending-active">
              <div className="swiper-wrappers">
                <Swiper
                  key={swiperKey}
                  dir={ language == 'ar' ? 'rtl' : '' }
                  modules={[Navigation, A11y, Autoplay]}
                  spaceBetween={30}
                  loop={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                  }}
                  navigation={{
                    nextEl: ".trending-button-nexta",
                    prevEl: ".trending-button-preva",
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    500: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 2,
                    },
                    1200: {
                      slidesPerView: 3,
                    },
                    1400: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  {products.length ? (
                    <>
                      {products?.length &&
                        products?.map((item: any, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <div className="swiper-slides">
                                <div className="bd-trending__item text-center mb-30">
                                  <div className="bd-trending__product-thumb">
                                    <Link to={`/shop/${item?.id}/`}>
                                      <img
                                        width={500}
                                        height={500}
                                        style={{
                                            width: "100%",
                                            height: "auto",
                                        }}
                                        src={item?.photo}
                                        alt="product-img"
                                      />
                                    </Link>
                                    <div className="bd-product__action">
                                      <span
                                        className="cart-btn"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Quick Shop"
                                        onClick={() =>
                                          add_to_cart(item)
                                        }
                                      >
                                        <i className="fal fa-cart-arrow-down"></i>
                                      </span>
                                      <span
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Quick View"
                                        data-bs-toggle="modal"
                                        data-bs-target="#productmodal"
                                        onClick={() =>
                                          handleMoldalData(item?.id)
                                        }
                                      >
                                        <i className="fal fa-eye"></i>
                                      </span>
                                      <span
                                        className="wishlist-btn"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Quick Wishlist"
                                        onClick={() =>
                                          handleWishlistProduct(item)
                                        }
                                      >
                                        <i className="fal fa-heart"></i>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="bd-teanding__content">
                                    <h4 className="bd-product__title">
                                      <Link to={`/shop/${item?.id}`}>
                                        {item?.translations[language]?.name}
                                      </Link>
                                    </h4>
                                    <div className="bd-product__price d-flex justify-content-between align-items-center">
                                      <span className="bd-product__vendor">
                                        {
                                          item?.vendor && 
                                          <>
                                              <i className="fas fa-store m-2" />{item?.vendor?.name}
                                          </>
                                        }
                                      </span>
                                      <span className="bd-product__new-price">
                                        {item?.offer ? (
                                          <span className="bd-product__old-price">
                                            <del>
                                              {`${
                                                item?.offer?.price % 1 === 0
                                                  ? `${item?.offer?.price}.00`
                                                  : item?.price.toFixed(2)
                                              }`}  {constants.currency}
                                            </del>
                                          </span>
                                        ) : (
                                          <></>
                                        )}

                                        {item?.price % 1 === 0 ? (
                                          <span className="bd-product__new-price">
                                            {`${item?.price}.00`} {constants.currency}
                                          </span>
                                        ) : (
                                          <span className="bd-product__new-price">
                                            {item?.price.toFixed(2)} {constants.currency}
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="bd-product__tag">
                                    {item?.offer ? (
                                      <>
                                        <span className="tag-text danger-bg">
                                          {" "}
                                          {item.offerPersent}%
                                        </span>
                                      </>
                                    ) :
                                    ( 
                                        item?.featured == 1 ? (
                                          <>
                                            <span className="tag-text theme-bg">
                                              {" "}
                                              {translations?.nav?.featured}
                                            </span>
                                          </>
                                        ) : ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      <h2>No Product</h2>
                    </>
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductModal />
    </>
  );
};

export default TrendingProductSlider;
