"use client";
import useGlobalContext from "../../hooks/use-context";
import { CartProductTypeTwo } from "../../interFace/interFace";
import { wishlist_product } from "../../redux/slices/wishlistSlice";

import { Link } from 'react-router-dom';
import React from "react";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { constants } from "../../utils/constants";
import { useAppDispatch } from '../../hooks/store-hooks';
import { toast } from "react-toastify";

const SidebarWishlist = () => {
  const { openWishlist, setOpenWishlist } = useGlobalContext();
  const dispatch = useAppDispatch();
  const translations = useSelector((state: RootState) => state.user.translations);

  const wishlistProducts = useSelector(
    (state: RootState) => state?.wishlist?.wishlistProducts
  );
  const language: any = useSelector(
    (state: RootState) => state.user.language
  );
  const user = useSelector(
    (state: RootState) => state.user.user
  );
  const totalPrice = 0
  const handleDeleteProduct = (product: CartProductTypeTwo) => {
    if(user) dispatch(wishlist_product(product))
    else toast.error('Please Login First !')
  };

  return (
    <>
      <div className="fix">
        <div
          className={`sidebar-action sidebar-cart ${
            openWishlist ? "cart-open" : ""
          }`}
        >
          <div className="cartmini__wrapper">
            <div className="cartmini__title">
              <h4>{translations?.nav?.mywishlist}</h4>
            </div>
            <div className="cartmini__close">
              <button
                type="button"
                className="cartmini__close-btn"
                onClick={() => setOpenWishlist(false)}
              >
                <i className="fal fa-times"></i>
              </button>
            </div>
            <div className="cartmini__widget">
              {wishlistProducts.length ? (
                <>
                  <div className="cartmini__inner">
                    <ul>
                      {wishlistProducts.map((item, index) => {
                            return (
                              <li key={index}>
                                <div className="cartmini__thumb">
                                  <Link to={`/shop-details/${item.id}`}>
                                    <img
                                      width={50}
                                      height={100}
                                      style={{ width: "70px", height: "auto" }}
                                      src={item.photo}
                                      alt=""
                                    />
                                  </Link>
                                </div>
                                <div className="cartmini__content">
                                  <h5>
                                    <Link to={`/shop-details/${item.id}`}>
                                      {item?.translations[language]?.name}
                                    </Link>
                                  </h5>
                                  <h5>
                                      {item.price} {constants.currency}
                                  </h5>
                                </div>
                                <span
                                  className="cartmini__del"
                                  onClick={() => handleDeleteProduct(item)}
                                >
                                  <i className="fal fa-times"></i>
                                </span>
                              </li>
                            );
                      })}
                    </ul>
                  </div>
                  <div className="cartmini__checkout">
                    <div className="cartmini__checkout-btn">
                      <Link
                        onClick={() => setOpenWishlist(false)}
                        className="bd-fill__btn w-100"
                        to="/profile/Wishlist"
                      >
                        {translations?.nav?.viewwishlist}
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p className="text-center pt-20 text-capitalize">
                  {translations?.nav?.emptyWishlist}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={() => setOpenWishlist(false)}
        className={`offcanvas-overlay ${openWishlist ? "overlay-open" : ""}`}
      ></div>
    </>
  );
};

export default SidebarWishlist;
