"use client";
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Thumbs, Controller, Navigation } from "swiper/modules";
import "swiper/css/bundle";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import { cart_product } from "../../redux/slices/cartSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { wishlist_product } from "../../redux/slices/wishlistSlice";
import { getProduct } from "../../utils/ApiCalls";
import { constants } from "../../utils/constants";
import { useAppDispatch } from '../../hooks/store-hooks';
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {Helmet} from "react-helmet";

const ShopDetailsMain = () => {
  const dispatch = useAppDispatch();
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1); // Local quantity state
  const [swiperKey, setSwiperKey] = useState(0);
  const { id } = useParams();
  const language: any = useSelector(
    (state: RootState) => state.user.language
  );
  const user = useSelector(
    (state: RootState) => state.user.user
  );
  useEffect(() => {
    if(swiperKey >= 0) setSwiperKey(swiperKey + 1);
  }, [language]);
  const translations = useSelector((state: RootState) => state.user.translations);

  const [products, setProducts] = useState<any>(null);

  const [selectedImage, setSelectedImage] = useState<string | null>(null);


  useEffect(() => {
    get_product();
  }, []);

  useEffect(() => {
  }, [language]);

  const get_product = () => {
    setLoading(true);
    getProduct(parseInt(id as string))
      .then((response) => {
        setProducts(response.data.data);
        setSelectedImage(response.data.data?.photo || null); // Set the initial main image
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleAddToCart = () => {
    if (products) {
      if(user) dispatch(cart_product(products))
      else toast.error('Please Login First !')
    }
  };

  const handDecressCart = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handIncreaseCart = () => {
    if (products && quantity < products?.quantity)
      setQuantity(quantity + 1);
  };
  const handleWishlist = (item: any) => {
    if(user) dispatch(wishlist_product(item))
    else toast.error('Please Login First !')
  };

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{`${translations?.nav?.website} - ${products ? products?.translations[language].name : ''}`}</title>
      </Helmet>
      <Breadcrumb breadHome={"Home"} breadMenu={"Shop Details"} />

      <div className="bd__shop-details-area pt-115 pb-75">
        <div className="container small-container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="bd__shop-details-inner mb-55">
                <div className="row">
                  <div className="col-md-6">
                    <div className="product-details__thumb-inner small-device p-relative">
                      <div className="bd__shop-details-img-gallery mb-30">
                        <div className="product-details-active swiper-container">
                          <div className="swiper-wrappers">
                            { products && 
                              <Swiper
                                key={swiperKey}
                                dir={ language == 'ar' ? 'rtl' : 'ltr' }
                                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                                loop={true}
                                spaceBetween={0}
                                slidesPerView={1}
                                freeMode={false}
                                watchSlidesProgress={true}
                                modules={[
                                  Navigation,
                                  Controller,
                                  FreeMode,
                                  Thumbs,
                                ]}
                                navigation={{
                                  nextEl: ".product-details__button-next",
                                  prevEl: ".product-details__button-prev",
                                }}
                              >
                              <SwiperSlide>
                                <div className="swiper-slides">
                                  <div className="bd-product__details-large-img w-img">
                                    <img
                                      src={selectedImage || products?.photo}
                                      alt="product-details-img"
                                      width={577}
                                      height={577}
                                      style={{
                                        width: "100%",
                                        height: "auto",
                                      }}
                                    />
                                  </div>
                                </div>
                              </SwiperSlide>
                              {( products?.photos?.length > 0 )  &&
                                products.photos.map((item: any, index: number) => (
                                <SwiperSlide key={index}>
                                  <div className="swiper-slides">
                                    <div className="bd-product__details-large-img w-img">
                                      <img
                                        src={item.url}
                                        alt="product-details-img"
                                        width={577}
                                        height={577}
                                        style={{
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </SwiperSlide>
                                )) }
                              </Swiper>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="bd-product__details-small-img">
                        <div className="swiper-container product-details-nav">
                          <div className="swiper-wrappers">
                          { products && 
                            <Swiper
                              key={swiperKey}
                              dir={ language == 'ar' ? 'rtl' : 'ltr' }
                              onSwiper={setThumbsSwiper}
                              loop={true}
                              spaceBetween={0}
                              slidesPerView={4}
                              modules={[Thumbs]}
                              watchSlidesProgress={true}
                            >
                            <SwiperSlide key={-1}>
                              <div
                                className="swiper-slides m-img"
                                onClick={() => setSelectedImage(products?.photo)}
                              >
                                <div className="product-small__img">
                                  <img
                                    src={products?.photo}
                                    alt="product-details-img"
                                    width={70}
                                    height={70}
                                    style={{
                                      width: "100%",
                                      height: "auto",
                                    }}
                                  />
                                </div>
                              </div>
                            </SwiperSlide>
                              {products && ( products?.photos?.length > 0 )  &&
                                products.photos.map((item: any, index: number) => (
                                  <SwiperSlide key={index}>
                                    <div
                                      className="swiper-slides m-img"
                                      onClick={() => setSelectedImage(item.url)}
                                    >
                                      <div className="product-small__img">
                                        <img
                                          src={item.url}
                                          alt="product-details-img"
                                          width={70}
                                          height={70}
                                          style={{
                                            width: "100%",
                                            height: "auto",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                ))}
                            </Swiper>
                          }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="modal-product-info shop-details-info">
                      <h3>{products?.translations[language].name}</h3>
                      <div className="product-price mb-2">
                        <span>{products?.price} {constants.currency}</span>
                      </div>
                      <div className="modal-product-meta bd__product-details-menu-1">
                        <ul>
                          <li className="d-flex align-items-center">
                            <strong>{translations?.nav?.category}</strong>
                            <span>
                              <h5 className="mb-0">{products?.category_name}</h5>
                            </span>
                          </li>
                        </ul>
                      </div>
                      {
                        products?.vendor && (
                          <>
                            <div className="modal-product-meta bd__product-details-menu-1">
                              <ul>
                                <li className="d-flex align-items-center">
                                  <strong>{translations?.nav?.sold_by}</strong>
                                  <span>
                                      <h5 className="mb-0">
                                        <Link to={`/vendor/${products?.vendor?.id}`}>
                                          {products?.vendor?.name}
                                        </Link>
                                      </h5>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </>
                        )
                      }
                      <div className="modal-product-meta bd__product-details-menu-1">
                        <ul>
                          <li>
                            <strong>{translations?.nav?.description} </strong>
                            <span>
                            <h4 dangerouslySetInnerHTML={{ __html: products?.translations[language]?.description }} className="mb-0">
                            </h4>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="product-quantity-cart mb-25">
                        <div className="product-quantity-form">
                          <form onSubmit={(e) => e.preventDefault()}>
                            <button
                              onClick={handDecressCart}
                              className="cart-minus"
                            >
                              <i className="far fa-minus"></i>
                            </button>
                            <input
                              className="cart-input"
                              type="text"
                              readOnly
                              value={quantity}
                            />
                            <button
                              className="cart-plus"
                              onClick={handIncreaseCart}
                            >
                              <i className="far fa-plus"></i>
                            </button>
                          </form>
                        </div>
                        <span onClick={handleAddToCart}>
                          <a className="cart-btn bd-fill__btn" href="javascript:void(0)">
                            <i className="fal fa-cart-arrow-down"></i>
                            {translations?.nav?.addtocart}
                          </a>
                        </span>
                      </div>
                      <div className="bd__product-details-menu-3">
                        <ul>
                          <li>
                            <span
                              className="wishlist-btn"
                              title="Wishlist"
                              onClick={() =>
                                handleWishlist(products)
                              }
                            >
                              <i className="far fa-heart"></i>
                              <span>{translations?.nav?.addtowishlist}</span>
                            </span>
                          </li>
                          <li>
                            <span
                              className="wishlist-btn cart-btn"
                              title="Compare"
                            >
                              <i className="fas fa-exchange-alt"></i>
                              <span>Compare</span>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="bd__social-media">
                        <ul>
                          <li>{translations?.nav?.share} :</li>
                          <li>
                            <Link
                              to="https://www.facebook.com/"
                              target="_blank"
                            >
                              <i className="fab fa-facebook-f"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://twitter.com/?lang=en"
                              title="Twitter"
                            >
                              <i className="fab fa-twitter"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://www.linkedin.com/"
                              title="Linkedin"
                              target="_blank"
                            >
                              <i className="fab fa-linkedin"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://www.instagram.com/"
                              target="_blank"
                              title="Instagram"
                            >
                              <i className="fab fa-instagram"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopDetailsMain;
