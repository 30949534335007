"use client"

import PaymentIcon from '../../../sheardComponent/elements/icons/payment-icon';
import ReturnIcon from '../../../sheardComponent/elements/icons/return-icon';
import ShippingIcon from '../../../sheardComponent/elements/icons/shipping-icon';
import SupportIcon from '../../../sheardComponent/elements/icons/support-icon';
import { Link } from 'react-router-dom';

import React from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";


const ShippingInfo = () => {

    const translations = useSelector((state: RootState) => state.user.translations);

    return (
        <>
            <div className="bd-sm__features-wrapper mb-30">
            <div className="bd-features__item mb-20">
                <div className="bd-features__icon">
                    <ShippingIcon/>
                </div>
                <div className="bd-features__content">
                    <h4><Link to="/about">{translations?.nav?.shipping1}</Link></h4>
                    <span>{translations?.nav?.shipping11}</span>
                </div>
            </div>
            <div className="bd-features__item mb-20">
                <div className="bd-features__icon">
                    <ReturnIcon />
                </div>
                <div className="bd-features__content">
                    <h4><Link to="/about">{translations?.nav?.shipping2}</Link></h4>
                    <span>{translations?.nav?.shipping22}</span>
                </div>
            </div>
            <div className="bd-features__item mb-20">
                <div className="bd-features__icon">
                    <PaymentIcon />
                </div>
                <div className="bd-features__content">
                    <h4><Link to="/about">{translations?.nav?.shipping3}</Link></h4>
                    <span>{translations?.nav?.shipping33}</span>
                </div>
            </div>
            <div className="bd-features__item">
                <div className="bd-features__icon">
                    <SupportIcon />
                </div>
                <div className="bd-features__content">
                    <h4><Link to={"/about"}>{translations?.nav?.shipping4}</Link></h4>
                    <span>{translations?.nav?.shipping44}</span>
                </div>
            </div>
        </div>
        </>
    );
};

export default ShippingInfo;