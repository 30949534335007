"use client";
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Thumbs, Controller, Navigation } from "swiper/modules";
import "swiper/css/bundle";
import useGlobalContext from "../../hooks/use-context";

import { cart_product, update_cart_product } from "../../redux/slices/cartSlice";
import { wishlist_product } from "../../redux/slices/wishlistSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { CartProductTypeTwo } from "../../interFace/interFace";
import { getProduct } from '../../utils/ApiCalls';
import { constants } from "../../utils/constants";
import { useAppDispatch } from '../../hooks/store-hooks';
import { toast } from "react-toastify";

const ProductModal = () => {
  const language = useSelector(
    (state: RootState) => state.user.language
  );

  const { modalId } = useGlobalContext();
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const [myProduct, setMyProduct] = useState<any>(null);
  const [totalCart, setTotalCart] = useState<any>(1);
  const [swiperKey, setSwiperKey] = useState(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (parseInt(modalId) > 0)
        getProductData(parseInt(modalId));
  }, [modalId]);
  
  useEffect(() => {
    if (parseInt(modalId) > 0)
      setSwiperKey((prevKey) => prevKey + 1);
  }, [language]);

  const user = useSelector(
    (state: RootState) => state.user.user
  );


  const handleAddToCart = (product: CartProductTypeTwo) => {
    if(user) dispatch(cart_product(product))
    else toast.error('Please Login First !')
  };

  const handleWishlist = (product: CartProductTypeTwo) => {
    if(user) dispatch(wishlist_product(product))
    else toast.error('Please Login First !')
  }

  const getProductData = (id: number) => {

    getProduct(id).then(response => {
        setMyProduct(response.data.data)
    }).catch(error => {
          console.log(error)
    });

  };
  return (
    <div
      className="product__modal-sm modal fade"
      id="productmodal"
      //   tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="product__modal">
            <div className="product__modal-wrapper p-relative">
              <button
                type="button"
                className="close product__modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fal fa-times"></i>
              </button>
              <div className="modal__inner">
                <div className="bd__shop-details-inner">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="product-details__thumb-inner p-relative">
                        <div className="bd__shop-details-img-gallery mb-30">
                          <div className="product-details-active swiper-container">
                            <div className="swiper-wrappers">
                            {myProduct &&
                              <Swiper
                                dir={ language == 'ar' ? 'rtl' : '' }
                                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                                loop={true}
                                spaceBetween={0}
                                slidesPerView={1}
                                freeMode={false}
                                watchSlidesProgress={true}
                                modules={[
                                  Navigation,
                                  Controller,
                                  FreeMode,
                                  Thumbs,
                                ]}
                                navigation={{
                                  nextEl: ".product-details__button-next",
                                  prevEl: ".product-details__button-prev",
                                }}
                              >
                                <SwiperSlide key={0}>
                                  <div className="swiper-slides">
                                    <div className="bd-product__details-large-img w-img">
                                      <img
                                        src={myProduct?.photo}
                                        alt="product-details-img"
                                        width={450}
                                        height={450}
                                        style={{
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </SwiperSlide>
                                {myProduct &&
                                  myProduct?.photos?.map(
                                    (item: any, index: any) => {
                                      return (
                                        <SwiperSlide key={index + 1}>
                                          <div className="swiper-slides">
                                            <div className="bd-product__details-large-img w-img">
                                              <img
                                                src={item.url}
                                                alt="product-details-img"
                                                width={450}
                                                height={450}
                                                style={{
                                                  width: "100%",
                                                  height: "auto",
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </SwiperSlide>
                                      );
                                    }
                                  )}
                              </Swiper>
                            }
                            </div>
                          </div>
                        </div>
                        <div className="bd-product__details-small-img">
                          <div className="swiper-container product-details-nav">
                            <div className="swiper-wrappers">
                            {myProduct &&
                              <Swiper
                                dir={ language == 'ar' ? 'rtl' : '' }
                                onSwiper={setThumbsSwiper}
                                loop={true}
                                spaceBetween={0}
                                slidesPerView={4}
                                modules={[Controller, FreeMode, Thumbs]}
                                watchSlidesProgress={false}
                              >
                                <SwiperSlide key={0}>
                                  <div className="swiper-slides">
                                    <div className="bd-product__details-large-img w-img">
                                      <img
                                        src={myProduct?.photo}
                                        alt="product-details-img"
                                        width={450}
                                        height={450}
                                        style={{
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      />
                                    </div>
                                  </div>
                              </SwiperSlide>
                                {myProduct &&
                                  myProduct?.photos?.map(
                                    (item: any, index: any) => (
                                      <SwiperSlide key={index + 1}>
                                        <div className="swiper-slides m-img">
                                          <div className={`product-small__img`}>
                                            <img
                                              src={item.url}
                                              alt="product-details-img"
                                              width={70}
                                              height={70}
                                              style={{
                                                width: "100%",
                                                height: "auto",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </SwiperSlide>
                                    )
                                  )}
                              </Swiper>
                            }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="modal-product-info modal-product__details-content">
                        <h3>{myProduct?.translations[language]?.name}</h3>
                        <div className="product-price">
                          {myProduct?.offer === true ? (
                            <>
                              <span>
                                {constants.currency}
                                {myProduct?.offer?.price % 1 === 0
                                  ? `${myProduct?.offer?.price}.00`
                                  : myProduct?.offer?.price.toFixed(2)}
                              </span>
                              <del>
                                {`$${
                                  myProduct?.price % 1 === 0
                                    ? `${myProduct?.price}.00`
                                    : myProduct?.price.toFixed(2)
                                } ${constants.currency}`}
                              </del>
                              </>
                          ) : (
                            <>
                              <span>
                                {myProduct?.price % 1 === 0
                                  ? `${myProduct?.price}.00`
                                  : myProduct?.price.toFixed(2)} {constants.currency}
                                  
                              </span>
                            </>
                          )}
                        </div>
                        <div className="modal-product-meta bd__product-details-menu-1">
                          <ul>
                            <li>
                              <strong>Category:</strong>
                              <span>
                                <Link to="/shop">{ myProduct?.category_translations[language]?.name }</Link>
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="product-quantity-cart mb-25">
                          <div className="product-quantity-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                              <button
                                onClick={() => setTotalCart( ( totalCart - 1) )}
                                className="cart-minus"
                              >
                                <i className="far fa-minus"></i>
                              </button>
                              <input
                                className="cart-input"
                                type="text"
                                readOnly
                                value={totalCart}
                                onChange={(e) => setTotalCart(e.target.value)}
                              />
                              <button
                                className="cart-plus"
                                onClick={() => setTotalCart( ( totalCart + 1) )}
                              >
                                <i className="far fa-plus"></i>
                              </button>
                            </form>
                          </div>
                          <span >
                            <a
                              className="cart-btn bd-fill__btn"
                              onClick={ () => {handleAddToCart(myProduct)}}
                            >
                              <i className="fal fa-cart-arrow-down"></i> Add To Cart
                            </a>
                          </span>
                        </div>
                        <div className="bd__product-details-menu-3">
                          <ul>
                            <li>
                              <span
                                className="wishlist-btn"
                                title="Wishlist"
                                onClick={() =>
                                  handleWishlist(myProduct)
                                }
                              >
                                <i className="far fa-heart"></i>
                                <span>Add to Wishlist</span>
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="bd__social-media">
                          <ul>
                            <li>Share:</li>
                            <li>
                              <Link
                                to="https://www.facebook.com/"
                                target="_blank"
                              >
                                <i className="fab fa-facebook-f"></i>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="https://twitter.com/?lang=en"
                                title="Twitter"
                              >
                                <i className="fab fa-twitter"></i>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="https://www.linkedin.com/"
                                title="Linkedin"
                                target="_blank"
                              >
                                <i className="fab fa-linkedin"></i>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="https://www.instagram.com/"
                                target="_blank"
                                title="Instagram"
                              >
                                <i className="fab fa-instagram"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
