
import {Helper} from './Helper';

const Host = "https://api.laimonah-scc.com/api"
//const Host = "/api"

const extract_params = (params: any[] = []) => {

    let urlParams = '';
    for(var i = 0; i < params.length; i++)
        urlParams += `&${Object.entries(params[i])[0][0]}=${Object.entries(params[i])[0][1]}`;
    return urlParams;
}

export async function getCities(page = 1, results = 15, params: any[] = []) {
    return Helper.Get(Host + `/cities?page=${page}&results=${results}${extract_params(params)}`);
}
export async function getCategoriesList(page = 1, results = 15, params: any[] = []) {
    return Helper.Get(Host + `/categories?page=${page}&results=${results}${extract_params(params)}`);
}
export async function getFeaturedProductsList(page = 1, results = 15, params: any[] = []) {
    return Helper.Get(Host + `/products?featured=1&page=${page}&results=${results}${extract_params(params)}`);
}
export async function getProductsList(page = 1, results = 15, params: any[] = []) {
    return Helper.Get(Host + `/products?page=${page}&results=${results}${extract_params(params)}`);
}
export async function getProduct(id: number) {
    return Helper.Get(Host + `/products/${id}`);
}
export async function getBlogsList(page = 1, results = 15, params: any[] = []) {
    return Helper.Get(Host + `/blogs?page=${page}&results=${results}${extract_params(params)}`);
}
export async function getBlog(id: number) {
    return Helper.Get(Host + `/blogs/${id}`);
}
export async function getPage(id: number) {
    return Helper.Get(Host + `/pages/${id}`);
}
export async function getPageList(page = 1, results = 15, params: any[] = []) {
    return Helper.Get(Host + `/pages?page=${page}&results=${results}${extract_params(params)}`);
}
export async function getBannersList(type = 1, page = 1, results = 15, params: any[] = []) {
    return Helper.Get(Host + `/banners?type=${type}&page=${page}&results=${results}${extract_params(params)}`);
}
export async function login(data: any) {
    return Helper.Post({ url: (Host + `/auth`), data });
}
export async function getBalancesList(page = 1, results = 15, params: any[] = []) {
    return Helper.Get(Host + `/balances?page=${page}&results=${results}${extract_params(params)}`);
}
export async function getWishlistList(page = 1, results = 15, params: any[] = []) {
    return Helper.Get(Host + `/wishlists?results=${results}&page=${page}${extract_params(params)}`);
}
export async function AddOrRemoveWishlist(id: any ) {
    return Helper.Post({ url: (Host + `/wishlists/${id}`)});
}
export async function clearWishlist() {
    return Helper.Delete({ url: (Host + `/wishlists/clear`)});
}
export async function getOrdersList(page = 1, results = 15, params: any[] = []) {
    return Helper.Get(Host + `/orders/byUser?results=${results}&page=${page}${extract_params(params)}`);
}
export async function getOrderDetails(id:string) {
    return Helper.Get(Host + `/orders/${id}`);
}
export async function addOrder(data: any) {
    return Helper.Put({url: Host + `/orders`, data});
}
//// cart Items Apis
export async function getCartItems() {
    return Helper.Get(Host + `/cartItems`);
}
export async function getCartItem(id: number) {
    return Helper.Get(Host + `/cartItems/${id}`);
}
export async function addCartItem(data: any) {
    return Helper.Put({ url: (Host + `/cartItems`), data });
}
export async function updateCartItem(data: any, id: number) {
    return Helper.Post({ url: (Host + `/cartItems/${id}`), data });
}
export async function clearCartItem() {
    return Helper.Delete({ url: (Host + `/cartItems/clear`) });
}
export async function deleteCartItem(id: number) {
    return Helper.Delete({ url: (Host + `/cartItems/${id}`) });
}
export async function getAddressList(page = 1, results = 15, params: any[] = []) {
    return Helper.Get(Host + `/userAddresses?page=${page}&results=${results}${extract_params(params)}`);
}
export async function getVendorList(page = 1, results = 15, params: any[] = []) {
    return Helper.Get(Host + `/vendors?page=${page}&results=${results}${extract_params(params)}`);
}
export async function getVendorDetails(id:string) {
    return Helper.Get(Host + `/vendors/${id}`);
}
export async function getVendorProducts(id:string ,page:number) {
    return Helper.Get(Host + `/products?vendor_id=${id}&page=${page}`);
}
export async function addVendor(data: any) {
    return Helper.Put({ url: (Host + `/vendors`), data });
}
export async function addAddress(data: any) {
    return Helper.Put({ url: (Host + `/userAddresses`), data });
}
export async function deleteAddress(id: number) {
    return Helper.Delete({ url: (Host + `/userAddresses/${id}`) });
}
export async function updateAddress(id: number ,data: any) {
    return Helper.Post({ url: (Host + `/userAddresses/${id}`), data });
}
