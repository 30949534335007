import React from 'react';

const ShippingIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26.978" height="20.023" viewBox="0 0 26.978 20.023">
            <path id="Path_3498" data-name="Path 3498" d="M26.452,79.842V78.118a2.639,2.639,0,0,0-2.3-2.614l-2.024-3.95a2.218,2.218,0,0,0-2.05-1.34H16.124V67.58A1.583,1.583,0,0,0,14.543,66H2.108A1.583,1.583,0,0,0,.527,67.58V79.842A1.054,1.054,0,0,0,0,80.753v1.054a1.055,1.055,0,0,0,1.054,1.054H2.673a3.688,3.688,0,0,0,7.3,0h1.145a.527.527,0,0,0,0-1.054H9.973a3.66,3.66,0,0,0-.318-1.054h7.669a3.658,3.658,0,0,0-.318,1.054H15.86a.527.527,0,0,0,0,1.054h1.145a3.688,3.688,0,0,0,7.3,0h1.619a1.055,1.055,0,0,0,1.054-1.054V80.753A1.054,1.054,0,0,0,26.452,79.842Zm-3.5-4.358H20.392V73.376h1.483ZM6.745,67.053H9.906v3.89L8.561,70.27a.527.527,0,0,0-.471,0l-1.345.673Zm-5.691,13.7H2.991a3.658,3.658,0,0,0-.318,1.054H1.054Zm5.269,4.215a2.635,2.635,0,1,1,2.635-2.635,2.638,2.638,0,0,1-2.635,2.635ZM8.9,79.7a3.68,3.68,0,0,0-5.156,0H1.581V78.645h.527a.527.527,0,1,0,0-1.054H1.581V67.58a.528.528,0,0,1,.527-.527H5.691V71.8a.527.527,0,0,0,.763.471l1.872-.936,1.872.936a.527.527,0,0,0,.763-.471V67.053h3.583a.528.528,0,0,1,.527.527V79.7Zm7.223-8.431h3.952a1.193,1.193,0,0,1,1.112.767l.147.287H19.865a.527.527,0,0,0-.527.527v3.162a.527.527,0,0,0,.527.527h3.952a1.583,1.583,0,0,1,1.49,1.054h-.436a.527.527,0,1,0,0,1.054H25.4V79.7H23.233a3.68,3.68,0,0,0-5.156,0H16.124Zm4.531,13.7a2.635,2.635,0,1,1,2.635-2.635A2.638,2.638,0,0,1,20.655,84.968Zm3.65-3.162a3.66,3.66,0,0,0-.318-1.054h1.937v1.054Z" transform="translate(0 -65.999)" fill="#1c1d1b"></path>
        </svg>
    );
};

export default ShippingIcon;