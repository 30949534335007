import { RootState } from '@/redux/store';
import { useSelector } from 'react-redux';


export const useUniqueProductCount = () => {
  const cartProducts = useSelector((state: RootState) => state.cart.cartProducts);
  return cartProducts.length;
};
export const useUniqueWishlstCount = () => {
  const wishlistProducts = useSelector((state: RootState) => state.wishlist.wishlistProducts);
  return wishlistProducts?.length;
};
