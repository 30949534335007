import { NavMenuItem } from "@/interFace/interFace";

const getNavMenusList = (translations: any): NavMenuItem[] => {
    // Check if translations and translations?.nav exist
    const navTranslations = translations?.nav || {};

    return [
        {
            id: 1,
            link: '/',
            title: navTranslations?.home || 'Home',
            hasDropdown: false,
            megamenu: false,
        },
        {
            id: 2,
            link: '/about',
            title: navTranslations?.about || 'About Us',
            hasDropdown: false,
            megamenu: false,
        },
        {
            id: 3,
            link: '/shop',
            title: navTranslations?.shop || 'Shop',
            hasDropdown: false,
            megamenu: false,
            dropdownItems: [
                { link: '/shop/category1', title: navTranslations?.category1 || 'Category 1' },
                { link: '/shop/category2', title: navTranslations?.category2 || 'Category 2' },
            ],
        },
        {
            id: 4,
            link: '/vendor',
            title: navTranslations?.vendor || 'Vendor',
        },
        {
            id: 5,
            link: '/blog',
            title: navTranslations?.blog || 'Blog',
        },
        {
            id: 6,
            link: '/contact',
            title: navTranslations?.contact || 'Contact',
        },
    ];
};

export default getNavMenusList;
