"use client";
import React from "react";
import { useNavigate } from 'react-router-dom';
import { setUser } from "../../../redux/slices/userSlice";
import { useAppDispatch } from '../../../hooks/store-hooks';
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface Props {
  slug: string
}

const ProfileSidebar = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const translations = useSelector((state: RootState) => state.user.translations);

  const navigateTo = (slug: string) => {
    navigate(`/profile/${slug}`);
  }

  const logout = () => {
    dispatch(setUser(null));
    window.location.href = "/login";
  };

  return (
    <div className="col-xl-3 col-lg-4">
      <div className="student-profile-sidebar mb-30">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={props.slug === "Dashboard" || !props.slug ? "nav-link active" : "nav-link"}
              onClick={() => navigateTo("Dashboard")}
            >
              <i className="fas fa-tachometer-alt-fast"></i>
              {translations?.nav?.dashboard}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={props.slug === "Profile" ? "nav-link active" : "nav-link"}
              onClick={() => navigateTo("Profile")}
            >
              <i className="fas fa-user"></i>{translations?.nav?.myprofile}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={props.slug === "Orders" ? "nav-link active" : "nav-link"}
              onClick={() => navigateTo("Orders")}
            >
              <i className="fas fa-cart-plus"></i> {translations?.nav?.myorder}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={props.slug === "Payments" ? "nav-link active" : "nav-link"}
              onClick={() => navigateTo("Payments")}
            >
              <i className="fa-solid fa-money-check"></i> {translations?.nav?.mypayment}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={props.slug === "UserAddress" ? "nav-link active" : "nav-link"}
              onClick={() => navigateTo("UserAddress")}
            >
              <i className="fa-solid fa-location-dot"></i> {translations?.nav?.useraddress}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={props.slug === "Wishlist" ? "nav-link active" : "nav-link"}
              onClick={() => navigateTo("Wishlist")}
            >
              <i className="fa-solid fa-heart"></i>
              {translations?.nav?.mywishlist}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              onClick={logout}
              className="nav-link"
            >
              <i className="fas fa-sign-out-alt"></i>{translations?.nav?.logout}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileSidebar;
