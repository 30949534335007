import React, { useState } from "react";
import axios from "axios";
import { addVendor } from "../../utils/ApiCalls";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from 'react-router-dom';
import { set } from "react-hook-form";

interface FormData {
  name: string;
  email: string;
  phone: string;
  photo: File | null;
  cover: File | null;
  languages: {
    [key: number]: {
      name: string;
      description: string;
      address: string;
    };
  };
}

const VendorRegister = () => {

  const translations = useSelector((state: RootState) => state.user.translations);
  const [loading, setloading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    phone: "",
    photo: null,
    cover: null,
    languages: {
      1: { name: "", description: "" ,address:"" }, // Arabic
      2: { name: "", description: "",address:"" }, // English
    },
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    langId?: number,
    field?: string
  ) => {
    const { name, value } = e.target;

    if (e.target instanceof HTMLInputElement && e.target.files) {
      const files = e.target.files;
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0], // For file inputs (photo/cover)
      }));
    } else if (langId && field) {
      setFormData((prevData) => ({
        ...prevData,
        languages: {
          ...prevData.languages,
          [langId]: {
            ...prevData.languages[langId],
            [field]: value,
          },
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setloading(true)



    // Prepare form data for API call
    const data = new FormData();
    data.append("name", formData.languages[1].name);
    data.append("email", formData.email);
    data.append("phone", formData.phone);
    if (formData.photo) {
      data.append("photo_file", formData.photo);
    }
    if (formData.cover) {
      data.append("cover_file", formData.cover);
    }
    data.append("languages[1][name]", formData.languages[1].name);
    data.append("languages[1][description]", formData.languages[1].description);
    data.append("languages[1][address]", formData.languages[1].address);
    data.append("languages[2][name]", formData.languages[2].name);
    data.append("languages[2][description]", formData.languages[2].description);
    data.append("languages[2][address]", formData.languages[2].address);
    data.append("_method", "PUT");

    try {
      const response = await addVendor(data)
      if (response.status == 200){
          toast.success(translations.nav.operation_success)
          setloading(false)
          navigate(`/`); 
      }
        else{
            toast.error(response.response.data.message);
            setloading(false)
        }

    } catch (error) {
      toast.error(translations.nav.data_validation_error);
      setloading(false)
    }
  };

  return (
    <div className="container mt-5">
      <div className="vendor-form-wrapper  p-5 rounded ">
        <h3 className="text-center mb-4">{translations.nav.vendorregister}</h3>
        <form onSubmit={handleSubmit} className="row g-3">
          <div className="col-md-12 bd-postbox__singel-input">
            <label htmlFor="languages-1-name" className="form-label">
            {translations.nav.name} {translations.nav.ar}
            </label>
            <input
              type="text"
              id="languages-1-name"
              name="languages[1][name]"
              className="form-control"
              value={formData.languages[1].name}
              onChange={(e) => handleChange(e, 1, "name")}
              required
            />
          </div>
          <div className="col-md-6 bd-postbox__singel-input">
            <label htmlFor="languages-1-description" className="form-label">
            {translations.nav.description} {translations.nav.ar}
            </label>
            <textarea
              id="languages-1-description"
              name="languages[1][description]"
              className="form-control"
              value={formData.languages[1].description}
              onChange={(e) => handleChange(e, 1, "description")}
              required
            />
          </div>
          <div className="col-md-6 bd-postbox__singel-input">
            <label htmlFor="languages-1-description" className="form-label">
            {translations.nav.location} {translations.nav.ar}
            </label>
            <textarea
              id="languages-1-address"
              name="languages[1][address]"
              className="form-control"
              value={formData.languages[1].address}
              onChange={(e) => handleChange(e, 1, "address")}
              required
            />
          </div>
          <div className="col-md-12 bd-postbox__singel-input">
            <label htmlFor="languages-2-name" className="form-label">
            {translations.nav.name} {translations.nav.en}
            </label>
            <input
              type="text"
              id="languages-2-name"
              name="languages[2][name]"
              className="form-control"
              value={formData.languages[2].name}
              onChange={(e) => handleChange(e, 2, "name")}
              required
            />
          </div>
          <div className="col-md-6 bd-postbox__singel-input">
            <label htmlFor="languages-2-description" className="form-label">
            {translations.nav.description} {translations.nav.en}
            </label>
            <textarea
              id="languages-2-description"
              name="languages[2][description]"
              className="form-control"
              value={formData.languages[2].description}
              onChange={(e) => handleChange(e, 2, "description")}
              required
            />
          </div>
          <div className="col-md-6 bd-postbox__singel-input">
            <label htmlFor="languages-2-description" className="form-label">
            {translations.nav.location} {translations.nav.en}
            </label>
            <textarea
              id="languages-2-address"
              name="languages[2][address]"
              className="form-control"
              value={formData.languages[2].address}
              onChange={(e) => handleChange(e, 2, "address")}
              required
            />
          </div>
          <div className="col-md-6 bd-postbox__singel-input">
            <label htmlFor="email" className="form-label">
              {translations.nav.email}
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-6 bd-postbox__singel-input">
            <label htmlFor="phone" className="form-label">
            {translations.nav.phone}
            </label>
            <input
              type="number"
              id="phone"
              name="phone"
              className="form-control"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-6 bd-postbox__singel-input">
            <label htmlFor="photo" className="form-label">
            {translations.nav.photo}
            </label>
            <input
              type="file"
              id="photo"
              name="photo"
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-6 bd-postbox__singel-input">
            <label htmlFor="cover" className="form-label">
            {translations.nav.cover}
            </label>
            <input
              type="file"
              id="cover"
              name="cover"
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-12 text-center mt-4">
            <button type="submit" className="bd-fill__btn" >
            { loading ? translations.nav.loading  : translations.nav.sumbit} 
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VendorRegister;
