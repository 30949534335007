import React from "react";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const AboutSectionTwo = ({item, loading}: any) => {
  
  const language: any = useSelector(
    (state: RootState) => state.user.language
  );
  return (
    <section className="bd-about__area pt-130 pb-65">
      <div className="container">
        <div className="row g-0">
          <div className="col-xxl-5 col-xl-5 col-lg-6">
            <div className="bd-about__wrapper mb-60">
              <div className="bd-about__image-1 m-img mb-60 w-100 position-relative pe-5">
                {
                  loading ? 
                    ( <Skeleton height="400px" width="100%" /> )
                    :
                    ( <img src={item?.photo as string} alt="about-image" className="w-100 h-auto" /> )
                }
                
              </div>
            </div>
          </div>
          <div className="col-xxl-7 col-xl-7 col-lg-6">
            <div className="bd-about__content-box mb-60">
              <div className="bd-section__title-wrapper mb-50">
                <span className="bd-sub__title">About Us</span>
                <h2 className="bd-section__title mb-30">
                  { item?.translations[language].name }
                </h2>
              </div>
              <div className="bd-about__inner">
                    <div className="bd-about__info" dangerouslySetInnerHTML={{ __html: item?.translations[language]?.description }}>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSectionTwo;
