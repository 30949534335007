"use client";
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import HeroSliderThree from "./HeroSliderThree";
import BannerPreloader from "../../preloaders/BannerPreloader";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getBannersList } from '../../utils/ApiCalls';

const HeroSectionThree = () => {
  
  const language = useSelector(
    (state: RootState) => state.user.language
  );
  const [topBanners, setTopBanners] = useState<[]>([]);
  const [sideBanners, setSideBanners] = useState<[]>([]);

  useEffect(() => {
      getBannersList(1).then(response => {
          setTopBanners(response.data.data)
      }).catch(error => {
          console.log(error)
      });
      getBannersList(2, 1, 2).then(response => {
        setSideBanners(response.data.data)
      }).catch(error => {
          console.log(error)
      });
  }, []);
  
  return (
    <>
      <section className="bd-banner__area pt-30 pb-40">
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="row">
                {sideBanners.length ? (
                  sideBanners.map((item: any, index) => (
                    <div className="col-xl-12 col-md-6" key={index}>
                      <div
                        className="bd-singel__product-banner product-thumb-bg mb-30"
                        style={{ backgroundImage: `url(${item.photo})` }}
                      >
                        <div className="bd-product__banner-inner">
                          <div className="bd-product__banner-content banner-oil product__content-3">
                            <h3 className="bd-product__title">
                                {item.translations[language]?.name}
                            </h3>
                            <div className="text-[white] product__banner-price">
                              <h4 dangerouslySetInnerHTML={{ __html: item?.translations[language]?.description }}>
                              </h4>
                            </div>
                            { item.url != '' &&
                              (
                                  <Link
                                    className="bd-bn__btn-3"
                                    to={item.url}
                                  >
                                    Shop Now
                                  </Link>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <BannerPreloader />
                  </>
                )}
              </div>
            </div>
            <div className="col-xl-8">
              <HeroSliderThree product={topBanners} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSectionThree;
