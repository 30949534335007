"use client";
import React, { useState, useEffect } from "react";
import { getOrderDetails } from "../../../utils/ApiCalls"; // Adjust the import according to your actual file structure

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { constants } from "../../../utils/constants";
import {Helmet} from "react-helmet";

const OrderDetails: React.FC = () => {
  const language = useSelector(
    (state: RootState) => state.user.language
  );
  const translations = useSelector((state: RootState) => state.user.translations);

  const [order, setOrder] = useState<{ id: string; total: number; created_at: string; address: any; items: any[] } | null>(null);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getOrderDetails(id as string) // Adjust according to your API method
        .then(response => {
          setOrder({
            id: response.data.data.id,
            total: response.data.data.total,
            created_at: formatDate(response.data.data.created_at),
            address:response.data.data.area + ', ' + response.data.data.street + ', ' + response.data.data.building + ', ' + response.data.data.floor ,
            items: response.data.data.items,
          });
        })
        .catch(error => {
          console.error("Error fetching order details:", error);
        });
    }
  }, [id]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{`${translations?.nav?.website} - ${translations?.nav?.myorder}`}</title>
    </Helmet>
      <div className="container my-5">
        <h2 className="text-center mb-4">{translations?.nav?.orderdetailed}</h2>
        {order ? (
          <div className="card p-4 shadow-sm">
            <h3 className="mb-4">{translations?.nav?.orderid} : {order.id}</h3>
            <div className="d-flex justify-content-between">
              <p><strong>{translations?.nav?.total}:</strong> ${order.total}</p>
              <p><strong>{translations?.nav?.date}:</strong> {order.created_at}</p>
            </div>
            <p><strong>{translations?.nav?.location}:</strong> {order.address}</p>
            <h4 className="mt-4 mb-3">{translations?.nav?.item} :</h4>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>{translations?.nav?.photo}</th>
                  <th>{translations?.nav?.name}</th>
                  <th>{translations?.nav?.quntity}</th>
                  <th>{translations?.nav?.total}</th>
                </tr>
              </thead>
              <tbody>
                {order.items.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <img 
                        src={item.product_photo} 
                        alt={`Product ${item.id}`} 
                        width={50} 
                        height={50}
                        style={{ borderRadius: "5px" }}
                      />
                    </td>
                    <td>{item.translations[language]?.name}</td>
                    <td>{item.quantity}</td>
                    <td>${item.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>{translations?.nav?.loading}...</p>
        )}
      </div>
    </>
  );
};

export default OrderDetails;
