"use client";
import CountUpContent from "../../../components/common/counter/CountUpContent";
import React, { useState, useEffect } from "react";
import {
  useUniqueProductCount,
  useUniqueWishlstCount,
} from "../../../hooks/useCartQuantity";
import { getOrdersList } from "../../../utils/ApiCalls";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {Helmet} from "react-helmet";

const DashboardCounter = () => {
  const cartQuantity = useUniqueProductCount();
  const WishlistQuantity = useUniqueWishlstCount();
  const [ordersCount, setOrdersCount] = useState(1);
  const translations = useSelector((state: RootState) => state.user.translations);

  useEffect(() => {
    getOrdersList(1, 1000000)
      .then(response => {
        setOrdersCount(response.data.meta.total)
      })
  }, []);
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{`${translations?.nav?.website} - ${translations?.nav?.profile_dashboard}`}</title>
    </Helmet>
    <div className="row">
      <div className="col-xl-4 col-lg-6 col-md-4">
        <Link className="counter-wrapper text-center mb-30" to="/profile/Orders">
          <div className="counter-icon">
            <div className="counter-icon-wrap">{/* incon will be here */}</div>
            <div className="count-number">
            <CountUpContent number={ordersCount} text="" />
              <p> {translations?.nav?.myproduct} </p>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-xl-4 col-lg-6 col-md-4">
        <Link className="counter-wrapper text-center mb-30" to="/cart">
          <div className="counter-icon">
            <div className="counter-icon-wrap">{/* incon will be here */}</div>
            <div className="count-number">
              <span className="counter">
                <CountUpContent number={cartQuantity} text="" />
              </span>
              <p> {translations?.nav?.mycart}</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-xl-4 col-lg-6 col-md-4">
        <Link className="counter-wrapper text-center mb-30" to="/profile/Wishlist">
          <div className="counter-icon">
            <div className="counter-icon-wrap">{/* incon will be here */}</div>
            <div className="count-number">
              <span className="counter">
                <CountUpContent number={WishlistQuantity} text="" />
              </span>
              <p> {translations?.nav?.mywishlist} </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
    </>
  );
};

export default DashboardCounter;
