"use client";

const OrderFailed: React.FC = () => {


  return (
    <>
      <div className="d-flex flex-column align-items-center w-100" >
          <svg style={{width: '100px'}} fill="red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24l0 112c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-112c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
          <h3 className="mt-3">
            We're Sorry, an error occured with your order
          </h3>
          <h5>
            Please try again later, or contact the website administrator
          </h5>
      </div>
    </>
  );
};

export default OrderFailed;
