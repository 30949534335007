export const GenderData = [
    {
        id:1,
        categoryName:"Select Gender"
    },
    {
        id:1,
        categoryName:"Male"
    },
    {
        id:2,
        categoryName:"Female"
    }
]

export const countryes = [
    {
      id: 1,
      categoryName: "Australia",
    },
    {
      id: 2,
      categoryName: "Algeria",
    },
    {
      id: 3,
      categoryName: "India",
    },
    {
      id: 4,
      categoryName: "USA",
    },
    {
      id: 5,
      categoryName: "UK",
    },
    {
      id: 6,
      categoryName: "Albania",
    },
  ];