import useGlobalContext from "../../hooks/use-context";
import { CartProductTypeTwo } from "../../interFace/interFace";
import { cart_product } from "../../redux/slices/cartSlice";
import { wishlist_product } from "../../redux/slices/wishlistSlice";

import { Link } from 'react-router-dom';
import React from "react";
import { constants } from "../../utils/constants";
import { useAppDispatch } from '../../hooks/store-hooks';
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";


interface ListViewProductProps {
    loading: boolean;
    products: CartProductTypeTwo[];
}
  
const ListViewProduct : React.FC<ListViewProductProps> = ({ products ,loading  }) => {

  const { openModal, setOpenModal, setModalId } = useGlobalContext();
  const dispatch = useAppDispatch();

  const language: any = useSelector(
    (state: RootState) => state.user.language
  );
  const user = useSelector(
    (state: RootState) => state.user.user
  );

  const handleAddToCart = (product: CartProductTypeTwo) => {
    if(user) dispatch(cart_product(product))
    else toast.error('Please Login First !')
  };

  const handleWishlist = (product: CartProductTypeTwo) => {
    if(user) dispatch(wishlist_product(product))
    else toast.error('Please Login First !')
  }

  const handleMoldalData = (id: string) => {
    if (id) {
      setOpenModal(!openModal);
      setModalId(id);
    }
  };

  return (
    <>
      {loading ? ( 
        <div className="d-flex justify-content-center align-items-center my-5">
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : products.length ? (
        <>
          {products.map((item, index) => (
            <div className="bd-grid__single-item mb-30" key={index}>
              <div className="row align-items-center">
                <div className="col-xxl-4 col-lg-6 col-md-6">
                  <div className="bd-trending__item">
                    <div className="bd-trending__product-thumb text-center">
                      <Link to={`/shop-details/${item.id}`} >
                        <a>
                          <img
                            src={item.photo}
                            alt="product-img"
                            width={500}
                            height={500}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-8 col-lg-6 col-md-6">
                  <div className="bd-trending__content mb-25">
                    <div className="bd-product__content mb-10">
                      <h4 className="bd-product__title">
                        <Link to={`/shop-details/${item.id}`}>
                          <a>{item?.translations[language]?.name}</a>
                        </Link>
                      </h4>
                      <div className="bd-product__price">
                        <span className="bd-product__new-price">
                          {constants.currency} {item.price}
                        </span>
                      </div>
                    </div>
                    <p className="mb-25">{item?.translations[language]?.description?.slice(0, 220)}</p>
                    <div className="bd-product__action-btn">
                      <span
                        className="cart-btn bd-add__cart-btn"
                        title="Add to Cart"
                        onClick={() => handleAddToCart(item)}
                      >
                        <i className="fal fa-cart-arrow-down"></i>
                      </span>
                      <span
                        className="bd-cart__btn bd-add__cart-btn"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Quick View"
                        data-bs-toggle="modal"
                        data-bs-target="#productmodal"
                        onClick={() => handleMoldalData(item?.id?.toString())}
                      >
                        <i className="fal fa-eye"></i>
                      </span>
                      <span
                        className="wishlist-btn bd-add__cart-btn"
                        title="Add to Wishlist"
                        onClick={() => handleWishlist(item)}
                      >
                        <i className="fal fa-heart"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <p>No products available.</p>
      )}
    </>
  );
};


export default ListViewProduct;
