import React, { useEffect, useState } from "react";
import { getBlog } from "../../utils/ApiCalls";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {Helmet} from "react-helmet";

const BlogDetailsArea = () => {

  const language: any = useSelector(
    (state: RootState) => state.user.language
  );
  const { id } = useParams();
  
  const [item, setItem] = useState<any>(null);

  const translations = useSelector((state: RootState) => state.user.translations);
  useEffect(() => {
    getBlog(parseInt(id as string))
     .then((response) => {
        setItem(response.data.data)
     })
     .catch((error) => {
       console.error("Error fetching products:", error);
     });
  });

  const formatDate = (date: string) => {
    
    let d = new Date(date);
    d.setDate(d.getDate() + 1);
    return d.toISOString().substr(0,10);
  }
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{`${translations?.nav?.website} - ${item ? item?.translations[language]?.name : '' }`}</title>
      </Helmet>
      <div className="blog-area pt-115 pb-100">
        <div className="container small-container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-12">
              <div className="blog-main-wrapper mb-30">
                <div className="row">
                  <div className="blog-wrapper position-relative blog-details-wrapper mb-30">
                    <div className="blog-thumb ">
                      <img
                        src={item?.photo}
                        width={500}
                        height={500}
                        style={{ width: "100%", height: "auto" }}
                        alt="blog-img"
                      />
                    </div>
                    <div className="blog-content-wrapper">
                      <div className="blog-meta">
                        <div className="blog-date">
                          <i className="fa-solid fa-calendar-days"></i>
                          <span>{item? formatDate(item?.created_at) : ''}</span>
                        </div>
                      </div>
                      <div className="blog-content">
                        <h3>{item?.translations[language]?.name}</h3>
                        <p dangerouslySetInnerHTML={{ __html: item?.translations[language]?.description }}></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetailsArea;
