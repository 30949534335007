"use client";

import { CartProductTypeTwo } from "../../interFace/interFace";
import {
  cart_product,
  clear_cart,
  update_cart_product,
  remove_cart_product,
} from "../../redux/slices/cartSlice";
import { RootState } from "../../redux/store";

import { Link } from 'react-router-dom';
import React from "react";
import { useSelector } from "react-redux";
import { constants } from "../../utils/constants";
import { useAppDispatch } from '../../hooks/store-hooks';
import {Helmet} from "react-helmet";

const CartSection = () => {
  const dispatch = useAppDispatch();
  const cartProducts = useSelector(
    (state: RootState) => state.cart.cartProducts
  );
  const totalPrice = useSelector(
    (state: RootState) => state.cart.total
  );
  const removeAllProduct = () => {
    dispatch(clear_cart());
  };


  const updateCartItem = (product: CartProductTypeTwo, quantity: number) => {
    dispatch(update_cart_product(product, quantity));
  };

  const handleDeleteProduct = (product: CartProductTypeTwo) => {
    dispatch(remove_cart_product(product));
  };

  const handleChange = (e: any, product: CartProductTypeTwo) => {
    dispatch(update_cart_product(product, e.target.value));
  };
  const translations = useSelector((state: RootState) => state.user.translations);

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{`${translations?.nav?.website} - ${translations?.nav?.shoppingcart}`}</title>
      </Helmet>
      {cartProducts.length === 0 && (
        <div className="container">
          <div className="empty-text pt-100 pb-100 text-center">
            <h3>{translations?.nav?.emptyCart}</h3>
          </div>
        </div>
      )}
      {cartProducts.length >= 1 && (
        <section className="cart-area pt-115 pb-130">
          <div className="container small-container">
            <div className="row">
              <div className="col-12">
                <div className="table-content table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="product-thumbnail">{translations?.nav?.images}</th>
                        <th className="cart-product-name">{translations?.nav?.product}</th>
                        <th className="product-price">{translations?.nav?.unitPrice}</th>
                        <th className="product-quantity">{translations?.nav?.quantity}</th>
                        <th className="product-subtotal">{translations?.nav?.total}</th>
                        <th className="product-remove">{translations?.nav?.remove}</th>
                      </tr>
                    </thead>
                    <tbody>
                     
                      {cartProducts.map((item, index) => {
                        return(
                          <tr key={index}>
                          <td className="product-thumbnail">
                            <Link to={`/shop-details/${item.id}`}>
                              <img
                                src={item.product_photo}
                                width={50}
                                height={50}
                                style={{ width: "100px", height: "auto" }}
                                alt=""
                              />
                            </Link>
                          </td>
                          <td className="product-name">
                            <Link to={`/shop-details/${item.id}`}>
                              {item.product_name}
                            </Link>
                          </td>
                          <td className="product-price">
                            <span className="amount">{item.price} {constants.currency}</span>
                          </td>
                          <td className="product-quantity text-center">
                            <div className="product-quantity mt-10 mb-10">
                              <div className="product-quantity-form">
                                <form onSubmit={(e) => e.preventDefault()}>
                                  <button
                                    type="button"
                                    className="cart-minus"
                                    onClick={() => updateCartItem(item, item.quantity - 1)}
                                  >
                                    <i className="far fa-minus"></i>
                                  </button>
                                  <input
                                    className="cart-input"
                                    type="text"
                                    onChange={ (e) => { handleChange(e, item)}}
                                    value={item.quantity}
                                  />
                                  <button
                                    type="button"
                                    className="cart-plus"
                                    onClick={() => updateCartItem(item, item.quantity + 1)}
                                  >
                                    <i className="far fa-plus"></i>
                                  </button>
                                </form>
                              </div>
                            </div>
                          </td>
                          <td className="product-subtotal">
                            <span className="amount">
                              {item.subtotal} {constants.currency}
                            </span>
                          </td>
                          <td
                            className="product-remove"
                            onClick={() => handleDeleteProduct(item)}
                          >
                            <i className="fa fa-times"></i>
                          </td>
                        </tr>
                        )
                      }
                        
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="coupon-all">
                    
                      <div className="coupon2">
                        <button
                          className="bd-border__btn"
                          name="update_cart"
                          type="submit"
                          onClick={removeAllProduct}
                        >
                          {translations?.nav?.clear_cart}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 ml-auto">
                    <div className="cart-page-total">
                      <h2>{translations?.nav?.cart_totals}</h2>
                      <ul className="mb-20">
                        <li>
                        {translations?.nav?.subtotal} <span>{totalPrice} {constants.currency}</span>
                        </li>
                        <li>
                        {translations?.nav?.total} <span>{totalPrice} {constants.currency}</span>
                        </li>
                      </ul>
                      <Link className="bd-border__btn" to="/checkout">
                        {translations?.nav?.proceed_to_checkout}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CartSection;
