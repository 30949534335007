"use client";
import React, { useState, useEffect } from "react";
import { getAddressList, addAddress, updateAddress, deleteAddress, getCities } from "../../../utils/ApiCalls";
import { Link } from 'react-router-dom';
import Pagination from "./Pagination"; 
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useSearchParams } from "react-router-dom";
import {Helmet} from "react-helmet";

const UserAddress: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const redirect = params.get('redirect');
  const language = useSelector(
    (state: RootState) => state.user.language
  );
  const user = useSelector(
    (state: RootState) => state.user.user
  );

  const translations = useSelector((state: RootState) => state.user.translations);

  const [address, setAddress] = useState<
    { id: string; area: string; street: string; building: string; floor: string; phone: number; country: string; city?: any }[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 10;
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState<string | null>(null);
  const [cities, setCities] = useState([]);
  const [newAddress, setNewAddress] = useState({
    area: "",
    user_id: user?.id,
    street: "",
    building: "",
    floor: "",
    phone: "",
    country: "",
    type: "1",
    city_id: 1,
    zipcode: "1111",
  });

  useEffect(() => {
    get_address(currentPage);
  }, [currentPage]);

  useEffect(() => {
    getCities(1, 50).then(result => {
      setCities(result.data.data)
    });
  }, []);

  const get_address = (page: number) => {
    setAddress([]);
    getAddressList(page, resultsPerPage)
      .then((response) => {
        setTotalPages(Math.ceil(response.data.total / resultsPerPage));
        const addressData = response.data.data.map((elem: any) => ({
          id: elem.id,
          area: elem.area,
          street: elem.street,
          building: elem.building,
          floor: elem.floor,
          phone: elem.phone,
          country: elem.country,
          city: elem.city,
        }));
        setAddress(addressData);
      })
      .catch((error) => {
        console.error("Error fetching addresses:", error);
      });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleInputChange = (e: any) => {
    setNewAddress({ ...newAddress, [e.target.name]: e.target.value });
  };

  const handleSaveAddress = () => {
    if (isEditMode && selectedAddressId) {
        delete newAddress.user_id
      updateAddress(parseInt(selectedAddressId), newAddress)
        .then(() => {
          setShowModal(false);
          resetForm();
          get_address(currentPage); // Refresh the address list
        })
        .catch((error) => {
          console.error("Error updating address:", error);
        });
    } else {
      addAddress(newAddress)
        .then(() => {
          setShowModal(false);
          resetForm();
          get_address(currentPage); // Refresh the address list
          if(redirect) navigate(redirect)
        })
        .catch((error) => {
          console.error("Error adding address:", error);
        });
    }
  };

  const handleEditAddress = (id: string) => {
    const addressToEdit = address.find((item) => item.id === id);
    if (addressToEdit) {
      setNewAddress({
        ...newAddress,
        area: addressToEdit.area,
        street: addressToEdit.street,
        building: addressToEdit.building,
        floor: addressToEdit.floor,
        phone: addressToEdit.phone.toString(),
        country: addressToEdit.country,
      });
      setSelectedAddressId(id);
      setIsEditMode(true);
      setShowModal(true);
    }
  };

  const handleDeleteAddress = (id: number) => {
    if (window.confirm("Are you sure you want to delete this address?")) {
      deleteAddress(id)
        .then((response) => {
          get_address(currentPage); 
        })
        .catch((error) => {
          console.error("Error deleting address:", error);
        });
    }
  };

  const resetForm = () => {
    setNewAddress({
      area: "",
      user_id: user?.id,
      street: "",
      building: "",
      floor: "",
      phone: "",
      country: "",
      type: "1",
      city_id: 1,
      zipcode: "1111",
    });
    setIsEditMode(false);
    setSelectedAddressId(null);
  };

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{`${translations?.nav?.website} - ${translations?.nav?.useraddress}`}</title>
    </Helmet>
    <div className="container my-5">
      {/* <h2 style={{ color: "#4CAF50", textAlign: "center", marginBottom: "30px" }}>
        My Addresses
      </h2> */}

      {/* Add Address Button */}
      <div className="text-end mb-4">
        <button
          className="btn btn-success"
          onClick={() => {
            resetForm();
            setShowModal(true);
          }}
        >
          <i className="fa-solid fa-plus"></i> {translations?.nav?.addaddress} 
        </button>
      </div>

      <div className="row">
        {address.map((item) => (
          <div key={item.id} className="col-md-6">
            <div className="card mb-4" style={{ borderColor: "#4CAF50" }}>
              <div className="card-body d-flex align-items-start">
                <div style={{ marginRight: "20px" }}>
                  <i className="fa-solid fa-location-dot"></i>
                </div>
                <div>
                  <p className="card-text" style={{ color: "#555" }}>
                    {item.city?.translations[language]?.name}, {item.area}, {item.street}, {item.building}, floor {item.floor}, 
                  </p>
                  <p className="card-text" style={{ color: "#555" }}>
                    <strong>{translations?.nav?.phone} :</strong> {item.phone}
                  </p>
                  <div className="d-flex">
                    <button
                      className="btn btn-outline-success btn-sm me-2"
                      onClick={() => handleEditAddress(item.id)}
                    >
                      <i className="fa-regular fa-pen-to-square"></i> {translations?.nav?.edit} 
                    </button>
                    <button
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => handleDeleteAddress(parseInt(item.id))}
                    >
                      <i className="fa-regular fa-trash-can"></i> {translations?.nav?.delete} 
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="d-flex justify-content-center">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>

      {/* Modal for Adding/Editing Address */}
      {showModal && (
        <div className="modal show d-block" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{isEditMode ? "Edit Address" : "Add New Address"}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label className="form-label">City</label>
                    <select className="form-control" name="city_id" onChange={handleInputChange} value={newAddress.city_id}>
                      {
                        cities && cities.map( (city:any, key:any) => {
                          return (
                            <option key={key} value={city.id} >{ city.translations[language].name }</option>
                          )
                        })
                      }
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label"> {translations?.nav?.area} </label>
                    <input
                      type="text"
                      name= {"area"} 
                      className="form-control"
                      value={newAddress.area}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label"> {translations?.nav?.street} </label>
                    <input
                      type="text"
                      name= {"street"} 
                      className="form-control"
                      value={newAddress.street}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label"> {translations?.nav?.building} </label>
                    <input
                      type="text"
                      name= {"building"} 
                      className="form-control"
                      value={newAddress.building}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label"> {translations?.nav?.floor} </label>
                    <input
                      type="number"
                      name= {"floor"} 
                      className="form-control"
                      value={newAddress.floor}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Phone</label>
                    <input
                      type="text"
                      name= {"phone"} 
                      className="form-control"
                      value={newAddress.phone}
                      onChange={handleInputChange}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                   {translations?.nav?.close} 
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleSaveAddress}
                >
                  {isEditMode ?  (translations?.nav?.updateaddress)  :  (translations?.nav?.saveaddress) }
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default UserAddress;
