import React from "react";
import ProfileSection from "./ProfileSection";

const UserProfileMain = () => {
  return (
    <>
      <ProfileSection />
    </>
  );
};

export default UserProfileMain;
