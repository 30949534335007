"use client";
import React, { useState , useEffect} from "react";
import { Link } from 'react-router-dom';
import Pagination from "../elements/product/Pagination";
import TeamPreloader from "../../preloaders/TeamPreloader";
import { getBlogsList } from "../../utils/ApiCalls";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import {Helmet} from "react-helmet";

const BlogGridSection = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setlastPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(12); 
  const [blogData, seBlogData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true); 
 
  const language: any = useSelector(
    (state: RootState) => state.user.language
  );

  const translations = useSelector((state: RootState) => state.user.translations);


  useEffect(() => {
    getBlogs(currentPage);
  }, [currentPage]);

  const getBlogs = (page: number) => {
    
    setLoading(true);

    getBlogsList(page, perPage)
    .then((response) => {
        seBlogData(response.data.data);
        setCurrentPage(response.data.meta.current_page);
        setlastPage(response.data.meta.last_page);
        setTotal(response.data.meta.total);
        setPerPage(response.data.meta.per_page);
        setLoading(false);
    })
    .catch((error) => {
        setLoading(false);
    });
  };

  const formatDate = (date: string) => {
    
    let d = new Date(date);
    d.setDate(d.getDate() + 1);
    return d.toISOString().substr(0,10);
  }
  return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`${translations?.nav?.website} - ${translations?.nav?.blog}`}</title>
        </Helmet>
        <section className="bd-news__grid-area pt-115 pb-65">
          <div className="container small-container">
            <div className="row">
              {blogData.length ? (
                blogData.map((item, num) => (
                  <div className="col-xl-4 col-lg-4 col-md-6" key={num}>
                    <div className="bd-news__item mb-60">
                      <div className="bd-news__thumb w-img" >
                        <Link to={`/blog-details/${item.id}`} className="d-flex" style={{maxHeight: '250px'}}>
                          <img
                            src={item.photo}
                            alt="news-Img"
                            width={500}
                            height={400}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </Link>
                      </div>
                      <div className="bd-news__content">
                        <div className="bd-news__meta-list">
                          <div className="bd-news__meta-item">
                            <span>
                              <i className="fa-regular fa-clock"></i>
                              {formatDate(item.created_at)}
                            </span>
                          </div>
                        </div>
                        <div className="bd-news__title">
                          <h3>
                            <Link to={`/blog-details/${item.id}`}>
                              {item.translations[language]?.name}
                            </Link>
                          </h3>
                        </div>
                        <Link
                          className="bd-news__btn"
                          to={`/blog-details/${item.id}`}
                        >
                        {translations?.nav?.readmore}
                          <span>
                            <i className="fa-solid fa-arrow-left"></i>
                            <i className="fa-solid fa-arrow-left"></i>
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <TeamPreloader />
                </>
              )}
            </div>

            <div className="row justify-content-center">
                <div className="col-xxl-12">
                  <Pagination
                    totalPages={lastPage}
                    currentPage={currentPage}
                    setPage={setCurrentPage}
                    Pagination_space="d-flex justify-content-center mt-40  mb-45"
                  />
                </div>
              </div>
          </div>
        </section>
    </>
  );
};

export default BlogGridSection;
