"use client"
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { Scrollbar, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import { productsPreloader } from "../../data/preloader-data";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getCategoriesList } from '../../utils/ApiCalls'

const CategorySlider = () => {
  
  const [categories, setCategories] = useState<[]>([]);
  const [swiperKey, setSwiperKey] = useState(0);

  const language = useSelector(
    (state: RootState) => state.user.language
  );
  
  useEffect(() => {
      //setSwiperKey((prevKey) => prevKey + 1)
  }, [language]);

  useEffect(() => {    
    getCategoriesList().then(response => {
      setCategories(response.data.data)
    }).catch(error => {
      console.log(error)
    });
  }, []);



  return (
    <div className="bd-catergory__area pb-40">
      <div className="container">
        {categories.length ? (
          <div className="row">
            <div className="col-12">
              <div className="bd-category-active swiper-container">
                <div className="swiper-wrappers">
                  <Swiper
                    dir={ language == 'ar' ? 'rtl' : '' }
                    modules={[Scrollbar, A11y, Autoplay]}
                    spaceBetween={20}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: true,
                    }}
                    breakpoints={{
                      400: {
                        slidesPerView: 2,
                      },
                      576: {
                        slidesPerView: 3,
                      },
                      768: {
                        slidesPerView: 4,
                      },
                      992: {
                        slidesPerView: 5,
                      },
                      1200: {
                        slidesPerView: 6,
                      },
                      1400: {
                        slidesPerView: 8,
                      },
                    }}
                  >
                    { categories?.length ? categories.map((item: any, num) => {
                      return (
                        <SwiperSlide key={num}>
                          <div className="swiper-slides">
                            <div className="bd-singel__category category_div_height text-center mb-30">
                              <div className="bd-category__img">
                                <Link to={`/shop/${item?.id}`}>
                                  <img
                                    src={item?.photo}
                                    alt="cateegory-img"
                                    width={80}
                                    height={100}
                                    style={{ maxWidth: "100%", maxHeight: "65px", width: "auto", height: "auto" }}
                                  />
                                </Link>
                              </div>
                              <div className="bd-category__text">
                                <span className="bd-category__title">
                                  <Link to={`/shop/${item?.id}`}>{item.translations[language]?.name}</Link>
                                </span>
                                
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      )
                    }
                   
                    ):
                    <>
                    {
                      productsPreloader.map((item,num)=>(
                        <SwiperSlide key={num}>
                        <div className="swiper-slides">
                          <div className="bd-singel__category category_div_height text-center mb-30">
                            <div className="bd-category__img og-custom-div">
                                
                            </div>
                            
                          </div>
                        </div>
                      </SwiperSlide>
                      ))
                    }
                    </>
                    }
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        ):
        <></>}
      </div>
    </div>
  );
};

export default CategorySlider;
