"use client";
import React from 'react';
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import {Helmet} from "react-helmet";
const AboutPagetitle = () => {

    const translations = useSelector((state: RootState) => state.user.translations);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${translations?.nav?.website} - ${translations?.nav?.about}`}</title>
            </Helmet>
            <section className="bd-page__banner-area include-bg page-overlay" style={{ backgroundImage: `url(${require('../../assets/img/about/about.jpg')})`, backgroundAttachment: 'fixed'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="bd-page__banner-content text-center">
                                <h2>{translations?.nav?.about}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutPagetitle;