
import { combineReducers } from '@reduxjs/toolkit';
import { cartSlice, get_cart_products } from './slices/cartSlice';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, Persistor } from 'redux-persist';
import { configureStore as configureStoreRTK } from '@reduxjs/toolkit';
import { wishlistSlice, get_wishlist_products } from './slices/wishlistSlice';
import { userSlice } from './slices/userSlice';

// RootState and AppDispatch declarations

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
};

const persistedReducer = persistReducer(persistConfig, combineReducers({
  cart: cartSlice.reducer,
  wishlist: wishlistSlice.reducer,
  user: userSlice.reducer,
 
}));

const makeStore = () =>
  configureStoreRTK({
      reducer: persistedReducer,
      middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });

export const store = makeStore(); // HERE!
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppPersistor = Persistor;
export type StoreState = typeof store.getState;

export const persistor = persistStore(store);

export default store;

