"use client";

import { CartProductTypeTwo } from "../../interFace/interFace";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { 
  getCartItems,
  updateCartItem,
  clearCartItem,
  addCartItem,
  deleteCartItem
 } from '../../utils/ApiCalls';

interface CartState {
  cartProducts: CartProductTypeTwo[];
  total: number
}

let initialState: CartState = {
  cartProducts: [],
  total: 0
};

export const cart_product = (payload: any, quantity:number = 1) => async (dispatch: any) => {
  const body = {
      product_id: payload.id,
      quantity: quantity
  }
  const response = await addCartItem(body);
  dispatch(handle_cart_product(response.data.data));
};
export const remove_cart_product = (payload: any) => async (dispatch: any) => {
    const response = await deleteCartItem(payload.id);
    dispatch(handle_remove_cart_product(response.data.data));
};
export const update_cart_product = (payload: any, quantity:number = 1) => async (dispatch: any) => {
  const body = {
      product_id: payload.id,
      quantity: quantity
  }
  const response = await updateCartItem(body, payload.id);
  dispatch(handle_update_cart_product(response.data.data));
};
export const clear_cart = () => async (dispatch: any) => {
  const confirmMsg = window.confirm(
    "Are you sure deleted your all cart items ?"
  );
  
  if (!confirmMsg)
      return false;

const response = await clearCartItem();
  dispatch(handle_clear_cart());
};
export const get_cart_products = () => async (dispatch: any) => {
  const response = await getCartItems();
  dispatch(handle_get_cart_products(response.data.data));
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    handle_cart_product: (state, action) => {
        state.cartProducts = action.payload.products;
        state.total = action.payload.total;
        toast.success(`The Product is added to your cart`);
    },
    handle_remove_cart_product: (state, action) => {
        state.cartProducts = action.payload.products;
        state.total = action.payload.total;
        toast.success(`The Product is removed`);
    },

    handle_clear_cart: (state) => {
        state.cartProducts = [];
    },

    handle_update_cart_product: (state, action) => {
        state.cartProducts = action.payload.products;
        state.total = action.payload.total;
        toast.success(`The Product is updated`);
    },

    handle_get_cart_products: (state, action) => {
        state.cartProducts = action.payload.products;
        state.total = action.payload.total;
    }
  },
});

export const {
  handle_cart_product,
  handle_remove_cart_product,
  handle_clear_cart,
  handle_update_cart_product,
  handle_get_cart_products
} = cartSlice.actions;

export default cartSlice.reducer;
