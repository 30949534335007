import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getVendorDetails, getVendorProducts } from "../../utils/ApiCalls"; 
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { constants } from "../../utils/constants";

interface Vendor {
  id: number;
  name: string;
  email: string;
  phone: string;
  photo: string;
  cover: string;
}

interface Product {
  id: number;
  name: string;
  price: number;
  photo: string;
}

const VendorDetails = () => {
  const { id } = useParams<{ id: string }>(); // Get the vendor ID from the URL
  const [vendor, setVendor] = useState<Vendor | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1)
  const navigate = useNavigate()
  const translations = useSelector((state: RootState) => state.user.translations);


  useEffect(() => {
    if (!id) {
      return;
    }

    const fetchVendorDetails = async () => {
      try {
        const response = await getVendorDetails(id); // API call to get vendor details
        setVendor(response.data.data);

      } catch (error) {
        console.error("Error fetching vendor details:", error);
      }
    };

    const fetchVendorProducts = async () => {
      try {
        const response = await getVendorProducts(id,page); // Fetch products for the vendor
        setProducts(response.data.data); // Adjust this according to your response structure
        setTotalPages(response.data.meta.last_page);
      } catch (error) {
        console.error("Error fetching vendor products:", error);
      }
    };

    // Fetch both vendor details and products
    Promise.all([fetchVendorDetails(), fetchVendorProducts()])
      .finally(() => {
        setLoading(false); // Set loading to false when both fetches complete
      });
  }, [id , page]);

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
      setLoading(true); 
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
      setLoading(true); 
    }
  };

  const handleCardClick = (id: number) => {
    navigate(`/shop-details/${id}`); 
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center my-5">
        <div className="spinner-border text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (!vendor) {
    return <div>Vendor details not found.</div>;
  }

  return (
    <div className="container">
      <div className="card mt-5 shadow">
        <img
          src={vendor.cover}
          className="card-img-top"
          alt={`${vendor.name} cover`}
          style={{ width: "100%", height: "300px", objectFit: "cover" }}
        />
        <div className="card-body text-center">
          <img
            src={vendor.photo}
            className="rounded-circle mb-3"
            alt={`${vendor.name} photo`}
            style={{
              width: "150px",
              height: "150px",
              objectFit: "cover",
              marginTop: "-75px",
              border: "5px solid white",
            }}
          />
          <h2 className="card-title mt-3" style={{ fontWeight: "bold", fontSize: "28px" }}>
            {vendor.name}
          </h2>
          <div className="contact-info mt-3" style={{ fontSize: "18px" }}>
            <p style={{ marginBottom: "8px" }}>
              <strong>{translations.nav.email}: </strong>
              <a href={`mailto:${vendor.email}`} style={{ color: "#007bff", textDecoration: "none" }}>
                {vendor.email}
              </a>
            </p>
            <p style={{ marginBottom: "8px" }}>
              <strong>{translations.nav.phone}: </strong>
              <a href={`tel:${vendor.phone}`} style={{ color: "#28a745", textDecoration: "none" }}>
                {vendor.phone}
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* Display Vendor Products */}
      <h3 style={{marginTop:"50px",marginBottom:"30px" , display:"flex"}} >{translations.nav.product} {vendor.name}</h3>
      <div className="row">
        {products.map((product) => (
          <div key={product.id} className="col-lg-2 col-md-4 mb-4" style={{cursor:"pointer"}} onClick={() => handleCardClick(product.id)}>
            <div className="card">
              <img
                src={product.photo}
                className="card-img-top"
                alt={product.name}
                style={{ height: "150px", objectFit: "cover" }}
              />
              <div className="card-body text-center">
                <h4 className="card-title">{product.name}</h4>
                <p className="card-text" style={{color:"green"}}>{product.price.toFixed(2)} {constants.currency}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {products.length !=0 && <div className="d-flex justify-content-between my-4">
        <button
          className="btn btn-secondary"
          onClick={handlePreviousPage}
          disabled={page === 1} 
        >
            {translations.nav.previous}
        </button>
        <span>Page {page} of {totalPages}</span>
        <button
          className="btn btn-secondary"
          onClick={handleNextPage}
          disabled={page === totalPages}
        >
           {translations.nav.next}
        </button>
      </div>}
    </div>
  );
};

export default VendorDetails;
