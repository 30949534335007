"use client";

import { CartProductTypeTwo } from "../../interFace/interFace";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import moment from "moment";
import { 
  getWishlistList,
  AddOrRemoveWishlist,
  clearWishlist,
 } from '../../utils/ApiCalls';

interface CartState {
  wishlistProducts: CartProductTypeTwo[];
}

const initialState: CartState = {
  wishlistProducts: [],
};

export const wishlist_product = (payload: any) => async (dispatch: any) => {
  const response = await AddOrRemoveWishlist(payload.id);
  if(response.data.success)
  {
      if(response.data.data == 'Deleted')
          toast.success('Product Removed From Wishlist !')
      else
          toast.success('Product Added To Wishlist !')
      dispatch(get_wishlist_products());
  }else
    toast.error('An error occured, please try again later !')
};
export const get_wishlist_products = () => async (dispatch: any) => {
  const response = await getWishlistList();
  dispatch(handle_wishlist_product(response.data.data));
};

export const clear_wishlist = () => async (dispatch: any) => {
  const confirmMsg = window.confirm(
    "Are you sure deleted your all cart items ?"
  );
  
  if (!confirmMsg)
      return false;

  const response = await clearWishlist();
  dispatch(handle_clear_wishlist(response.data.data));
};
export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    handle_clear_wishlist: (state) => {
        state.wishlistProducts = [];
    },
    handle_wishlist_product: (state, action) => {
        //state.wishlistProducts = [];
        state.wishlistProducts = action.payload;
    }
  },
});

export const {
  handle_clear_wishlist,
  handle_wishlist_product,
} = wishlistSlice.actions;

export default wishlistSlice.reducer;
