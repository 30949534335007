"use client"
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { constants } from "../../../utils/constants";
import {Helmet} from "react-helmet";
const MyProfile = () => {

  const translations = useSelector((state: RootState) => state.user.translations);


  const user = useSelector(
    (state: RootState) => state.user.user
  );
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{`${translations?.nav?.website} - ${translations?.nav?.myprofile}`}</title>
      </Helmet>
      <ul className="student-profile-info">
       
        <li>
          <h5>{translations?.nav?.name} :</h5>
          <span className="text-capitalize"> {user?.name} </span>
        </li>

        <li>
          <h5>{translations?.nav?.username} :</h5>
          <span> {user?.username} </span>
        </li>
        {
          user?.email1 && (
            <li>
              <h5>{translations?.nav?.email} :</h5>
              <span> {user?.email1} </span>
            </li>
          )
        }
        <li>
          <h5>{translations?.nav?.phone} :</h5>
          <span> {user?.mobile} </span>
        </li>
        <li>
          <h5>{translations?.nav?.gender} :</h5>
          <span> {user?.sex == 1 ? (translations?.nav?.male) : (translations?.nav?.female)} </span>
        </li>
        <li>
          <h5>{translations?.nav?.balance} :</h5>
          <span> {user?.balance.toLocaleString()} {constants.currency} </span>
        </li>
      </ul>
    </>
  );
};

export default MyProfile;
