"use client";
import { Link } from 'react-router-dom';
import React from "react";

import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const FooterThree = () => {
  const date = new Date();
  
  const translations = useSelector((state: RootState) => state.user.translations);

  return (
    <footer>
      <div className="bd-footer__area grey-bg pt-100 pb-60">
        <div className="bd-footer__style-2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="bd-footer__widget text-center mb-40">
                  <div className="bd-footer__logo">
                    <Link to="/">
                      <img
                        src={require("../../assets/img/logo/footer-logo.png")}
                        alt={translations?.footer?.logoAlt || "footer-logo"}
                        className="footer-logo"
                      />
                    </Link>
                  </div>
                </div>
                <div className="bd-footer__widget text-center mb-40">
                  <div className="bd-footer__link">
                    <ul>
                      <li>
                        <Link to="/">{translations?.nav?.home || "Home"}</Link>
                      </li>
                      <li>
                        <Link to="/about">
                          {translations?.nav?.about || "About Us"}
                        </Link>
                      </li>
                      <li>
                        <Link to="/shop">
                          {translations?.nav?.shop || "Shop"}
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog">
                          {translations?.nav?.blog || "Blog"}
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact">
                          {translations?.nav?.contact || "Contact"}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="bd-footer__widget text-center mb-40">
                  <div className="bd-footer__social">
                    <Link
                      to="https://www.facebook.com/"
                      target="_blank"
                      title="Facebook"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </Link>

                    <Link
                      to="https://twitter.com/?lang=en"
                      title="Twitter"
                    >
                      <i className="fab fa-twitter"></i>
                    </Link>

                    <Link
                      to="https://www.linkedin.com/"
                      title="LinkedIn"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin"></i>
                    </Link>

                    <Link
                      to="https://www.instagram.com/"
                      target="_blank"
                      title="Instagram"
                    >
                      <i className="fab fa-instagram"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bd-sub__fotter">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="bd-footer__copyright text-center">
                <ul>
                  <li>{translations?.nav?.rights || "All Rights Reserved"}</li>
                  <li>
                    {translations?.nav?.copyright || "Copyrighted by"} ©{date.getFullYear() + " "}
                    <span>
                      <Link to="https://themeforest.net/user/bdevs/portfolio">
                        Laimonah
                      </Link>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterThree;
