"use client";
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { Scrollbar, A11y, Autoplay, Pagination, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import HeroSliderPreloader from "../../preloaders/HeroSliderPreloader";


const HeroSliderThree = ({ product }: any) => {
  const [swiperKey, setSwiperKey] = useState(0);
  const language = useSelector(
    (state: RootState) => state.user.language
  );
  useEffect(() => {
    if(swiperKey >= 0) setSwiperKey((prevKey) => prevKey + 1);
  }, [language]);
  return (
    <div className="bd-banner-active-2 swiper-container">
      <div className="swiper-wrappers">
        <Swiper
          key={swiperKey}
          dir={ language == 'ar' ? 'rtl' : '' }
          modules={[EffectFade, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={30}
          effect={"fade"}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
            el: ".banner-pagination-2",
          }}
        >
         
          {
            product?.length ?
            <>
             {product.map((item: any, num: number) => {
            return (
              <SwiperSlide key={num}>
                <div className="swiper-slides">
                  <div
                    className="bd-singel__product-banner product-thumb-bg-2 mb-30"
                    style={{ backgroundImage: `url(${item.photo})` }}
                  >
                    <div className="bd-product__banner-inner  product__content-4">
                      <div className="bd-product__banner-content">
                        <h2 data-animation="fadeInLeft" data-delay="1.5s">
                          {item.translations[language]?.name}
                        </h2>
                        <p data-animation="fadeInLeft" data-delay="1.5s">
                          {item.translations[language]?.description} 
                        </p>
                          { item.url != '' &&
                            (
                                <Link
                                  className="bd-bn__btn-3"
                                  to={item.url}
                                >
                                  Shop Now
                                </Link>
                            )
                          }
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
            </>
            :
            <>
              <HeroSliderPreloader/>
            </>
          }
         
        </Swiper>
        <div className="banner-pagination-2"></div>
      </div>
    </div>

  );
};

export default HeroSliderThree;
