import React from 'react';

const EmailIcon = () => {
    return (
        <svg id="email_3_" data-name="email (3)" xmlns="http://www.w3.org/2000/svg" width="22.57" height="16.271" viewBox="0 0 22.57 16.271">
            <path id="Path_10" data-name="Path 10" d="M20.933,20.521H4.137A2.9,2.9,0,0,1,1.25,17.634V7.137A2.9,2.9,0,0,1,4.137,4.25h16.8A2.9,2.9,0,0,1,23.82,7.137v10.5A2.9,2.9,0,0,1,20.933,20.521Zm-16.8-14.7A1.312,1.312,0,0,0,2.825,7.137v10.5a1.312,1.312,0,0,0,1.312,1.312h16.8a1.312,1.312,0,0,0,1.312-1.312V7.137a1.312,1.312,0,0,0-1.312-1.312Z" transform="translate(-1.25 -4.25)" fill="#699c47" />
            <path id="Path_11" data-name="Path 11" d="M12.534,13.7a3.412,3.412,0,0,1-1.732-.472L1.638,7.778a.8.8,0,0,1-.283-1.05A.777.777,0,0,1,2.4,6.455l9.175,5.438a1.774,1.774,0,0,0,1.848,0L22.6,6.455a.777.777,0,0,1,1.05.273.8.8,0,0,1-.283,1.05l-9.1,5.448a3.412,3.412,0,0,1-1.732.472Z" transform="translate(-1.249 -4.145)" fill="#699c47" />
        </svg>
    );
};

export default EmailIcon;