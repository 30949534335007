
import { Link } from 'react-router-dom';
import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { changeLanguage } from "../../redux/slices/userSlice";
import { constants } from "../../utils/constants";
import { parseCookies, setCookie } from 'nookies';

const HeaderTopThree = () => {
    
  const language = useSelector(
    (state: RootState) => state.user.language
  );
  const user = useSelector(
    (state: RootState) => state.user.user
  );
  const translations = useSelector((state: RootState) => state.user.translations);

  const dispatch = useDispatch();

  const switchLanguage = (language: string) => {
        dispatch(changeLanguage(language));
        setCookie(null, 'language', language,{maxAge: 86400})
  };
  
    return (
        <div className="bd-top__bar-area theme-bg d-sm-block">
            <div className="container">
                <div className="row align-content-center">
                    <div className="col-xxl-6 col-xl-6 col-md-4 col-sm-5 align-items-center d-lg-flex d-none">
                        <div className="bd-header__top-link justify-content-start">
                            <Link to="/about">{translations?.nav?.about}</Link>
                            <Link to="/wishlist">{translations?.nav?.wishlist}</Link>
                            <Link to="/registerVendor">{translations?.nav?.vendorregister}</Link>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-md-4 col-12">
                        <div className="bd-treak__right bd-treak__right3">
                            <div className="border-lefts">
                                <select name="lan-select" id="lan-select" 
                                    className="language-select text-white" value={language}
                                    onChange={ (e) => {
                                        switchLanguage(e.target.value)
                                        }} >
                                    <option value="ar">عربي</option>
                                    <option value="en">English</option>
                                </select>
                            </div>
                            {
                                user && (
                                    
                                    <div className="border-left text-white">
                                        <span className="d-none d-sm-lg">{translations?.nav?.balance}</span>
                                        <span className="d-none d-sm-lg"> : </span>
                                        <span>{ user.balance }</span> 
                                        <span> { constants.currency }</span>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderTopThree;