"use client";
import React , {useState,useEffect} from "react";
import { getWishlistList } from "../../../utils/ApiCalls";
import { AddOrRemoveWishlist } from "../../../utils/ApiCalls";
import { Link } from 'react-router-dom';
import Pagination from "./Pagination";
import { number } from "yup";
import { constants } from "../../../utils/constants";
import {Helmet} from "react-helmet";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";


const MyWishlist: React.FC = ()  => {

    const [wishlist, setwishlist] = useState<{ id: string, name: string, price: number,photo:string }[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const resultsPerPage = 10;
    const [totalPages, setTotalPages] = useState(1);
    
    useEffect(() => {
        get_wishlist(currentPage);
    }, [currentPage]);
    const translations = useSelector((state: RootState) => state.user.translations);

    const get_wishlist = (page: number) => {
        setwishlist([])
        getWishlistList(page, resultsPerPage).then(response => {
            setTotalPages(response.data.total)
            response.data.data.forEach((elem: any) => {
                setwishlist(prev => [...prev, {
                    id: elem.id,
                    name: elem.name,
                    price: elem.price,
                    photo:elem.photo
                }]);
            });
        }).catch(error => {
            console.error("Error fetching wishlist:", error);
        });
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    }

    const handleWishlist = (id: any) => {
        AddOrRemoveWishlist(id).then(response=>{
            get_wishlist(currentPage)
        })

    }
    return(
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{`${translations?.nav?.website} - ${translations?.nav?.mywishlist}`}</title>
    </Helmet>
      <div className="bd-trending__item-wrapper">
        <div className="row">
          
          {wishlist?.length ? (
            <>
              {wishlist.map((item) => {
                return (
                  <div
                    className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6"
                    key={item.id}
                  >
                    <div className="bd-trending__item text-center mb-30 position-relative">
                      <div className="bd-trending__product-thumb border-5">
                        <Link to={`/shop-details/${item.id}`}>
                          <img
                            src={item.photo}
                            alt="product-img"
                            width={500}
                            height={500}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </Link>
                        <div className="bd-product__action">
                          <span
                            className="cart-btn"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Quick Shop"
                            // onClick={() => handleAddToCart(item)}
                          >
                            <i className="fal fa-cart-arrow-down"></i>
                          </span>

                          <span
                            className="wishlist-btn"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Quick Wishlist"
                            onClick={() => handleWishlist(item.id)}
                          >
                            <i className="fal fa-heart"></i>
                          </span>
                        </div>
                      </div>
                      <div className="bd-teanding__content">
                        <h4 className="bd-product__title">
                          <Link
                            className="text-capitalize"
                            to={`/shop-details/${item.id}`}
                          >
                            {item.name}
                          </Link>
                        </h4>
                        <div className="bd-product__price">
                       
                            <span className="bd-product__new-price">
                              {constants.currency} {`${item?.price}`}
                            </span>
                          
                        </div>
                      </div>
                    
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <p className="text-center">No Products In Wishlist </p>
            </>
          )}
           <div className="row justify-content-center">
              <div className="col-xxl-12">
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
              </div>
            </div>
        </div>
      </div>
    </>)

}
export default MyWishlist;