"use client";
import { Link } from 'react-router-dom';

import React, { useEffect, useState } from "react";
import { getBannersList } from '../../../utils/ApiCalls';
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";


const BrowseProductBanner = () => {
    const language = useSelector(
      (state: RootState) => state.user.language
    );
    const translations = useSelector((state: RootState) => state.user.translations);

    const [banners, setBanners] = useState<[]>([]);
  
    useEffect(() => {
        getBannersList(3).then(response => {
            setBanners(response.data.data)
        }).catch(error => {
            console.log(error)
        });
    }, []);
    return (
        <div className="bd-singel__banner-wrapper mb-40">
            {banners &&
            <div className="row">
                {banners.map((item: any, num) => (
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6" key={num}>
                    <div className="bd-singel__product-banner product-thumb-bg-3 mb-30" style={{ backgroundImage: `url(${item.photo})`}}>
                        <div className="bd-product__banner-inner">
                            <div className="bd-product__banner-content product__content-5">
                                <span>{item.translations[language]?.name}</span>
                                <h3>{item.translations[language]?.description}</h3>
                                { item.url != '' &&
                                    (
                                        <Link
                                            className="bd-bn__btn-3"
                                            to={item.url}
                                        >
                                              {translations?.nav?.buttonshop}
                                        </Link>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                ))}
            </div>
            }
        </div>
    );
};

export default BrowseProductBanner;