"use client";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import React, { useState, } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { login } from "../utils/ApiCalls"
import { setUser } from "../redux/slices/userSlice";
import { useAppDispatch } from '../hooks/store-hooks';
import {Helmet} from "react-helmet";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

interface FormData {
  email: string;
  password: string;
  rememberMe: boolean;
}
const LoginForm: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setloginError] = useState<string>("");
  const [loading, setloading] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const dispatch = useAppDispatch();
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setloading(true)
   
    login({username: data.email, password: data.password}).then(response => {

      setloading(false)
      const user = response.data.data;
      user.name = response.data.data?.first_name + " " + response.data.data?.father_name + " " + response.data.data?.last_name;
      dispatch(setUser(user))

      window.location.href = "/profile";

    }).catch(error => {
      setloading(false);
      setloginError("Invalid login credentials. Please try again.");
      console.log(error)
    })

  };
  const translations = useSelector((state: RootState) => state.user.translations);

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{`${translations?.nav?.website} - ${translations?.nav?.login}`}</title>
    </Helmet>
      <div className="bd-login__area pt-115 pb-130">
        <div className="container small-container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="Login-form-wrapper">
                <div className="bd-postbox__contact">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-xxl-12">
                        <div className="bd-postbox__singel-input">
                          <input
                            type="text"
                            defaultValue={""}
                            placeholder={translations?.nav?.EnterYourUsername}
                            {...register("email", {
                              required: translations?.nav?.RequiredUsername,
                            })}
                          />
                          {errors.email && (
                            <span className="error-message">
                              {errors.email.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xxl-12">
                        <div className="bd-password-box d-flex justify-content-between">
                          <input
                            type={showPassword ? "text" : "password"}
                            defaultValue={""}
                            placeholder={translations?.nav?.EnterPassword}
                            {...register("password", {
                              required: translations?.nav?.RequiredPassword,
                              minLength: 8,
                            })}
                          />
                          <span className="input-icon">
                            <button
                              type="button"
                              onClick={() => setShowPassword(!showPassword)}
                              className="password-toggle-btn"
                            >
                              <i
                                className={
                                  showPassword
                                    ? "fa-solid fa-eye"
                                    : "fa-regular fa-eye-slash"
                                }
                              ></i>
                            </button>
                          </span>
                          {errors.password && (
                            <span className="error-message">
                              {errors.password.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <span className="error-message">
                        {loginError && loginError}
                      </span>
                      <div className="bd-sigin__action-button mb-20">
                        <button className="bd-fill__btn w-100" type="submit">
                          {loading ? translations?.nav?.Loading : translations?.nav?.LoginNow}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
