import React from 'react';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "./style/index.scss";
import AppRoutes from './routes/Routes';
import {ToastContainer} from "react-toastify";
import { get_cart_products, handle_clear_cart } from "./redux/slices/cartSlice";
import { get_wishlist_products, handle_clear_wishlist } from "./redux/slices/wishlistSlice";
import { RootState } from "./redux/store";
import { useSelector } from "react-redux";
import { useAppDispatch } from './hooks/store-hooks';

function App() {
    const dispatch = useAppDispatch();
    const user = useSelector(
        (state: RootState) => state.user.user
    );
    if(user)
    {
        dispatch(get_cart_products());
        dispatch(get_wishlist_products());
    }else{
        dispatch(handle_clear_cart());
        dispatch(handle_clear_wishlist());
    }
  return (
      <>
        <AppRoutes/>
        <ToastContainer
          position="top-left"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </>
  )
}

export default App;
