import useGlobalContext from "../../hooks/use-context";
import { CartProductTypeTwo } from "../../interFace/interFace";
import { cart_product } from "../../redux/slices/cartSlice";
import { wishlist_product } from "../../redux/slices/wishlistSlice";
import { Link } from 'react-router-dom';
import React from "react";
import { constants } from "../../utils/constants";
import { useAppDispatch } from '../../hooks/store-hooks';
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

interface GridViewProductProps {
  loading: boolean;
  products: CartProductTypeTwo[];
}

const GridViewProduct: React.FC<GridViewProductProps> = ({ products ,loading  }) => {
  
  const { openModal, setOpenModal, setModalId } = useGlobalContext();
  const dispatch = useAppDispatch();

  const language: any = useSelector(
    (state: RootState) => state.user.language
  );

  const user = useSelector(
    (state: RootState) => state.user.user
  );

  const translations = useSelector((state: RootState) => state.user.translations);

  const handleAddToCart = (product: CartProductTypeTwo) => {
    if(user) dispatch(cart_product(product))
    else toast.error('Please Login First !')
  };

  const handleWishlist = (product: CartProductTypeTwo) => {
    if(user) dispatch(wishlist_product(product))
    else toast.error('Please Login First !')
  }

  const handleMoldalData = (id: string) => {
    if (id) {
      setOpenModal(!openModal);
      setModalId(id);
    }
  };

  return (
    <>
    {loading ? ( 
        <div className="d-flex justify-content-center align-items-center my-5">
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : products.length ? (
        <div className="row">
          {products.map((item, index) => (
            <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6" key={index}>
              <div className="bd-trending__item text-center mb-30 position-relative">
                <div className="bd-trending__product-thumb border-5">
                  <Link to={`/shop-details/${item.id}`} >
                    <img
                      src={item.photo}
                      alt={item.name}
                      width={500}
                      height={500}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </Link>
                  <div className="bd-product__action">
                    <span
                      className="cart-btn"
                      title="Add to Cart"
                      onClick={() => handleAddToCart(item)}
                    >
                      <i className="fal fa-cart-arrow-down"></i>
                    </span>
                    <span
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Quick View"
                        data-bs-toggle="modal"
                        data-bs-target="#productmodal"
                        onClick={() => handleMoldalData(item?.id?.toString())}
                      >
                        <i className="fal fa-eye"></i>
                      </span>
                    <span
                      className="wishlist-btn"
                      title="Add to Wishlist"
                      onClick={() => handleWishlist(item)}
                    >
                      <i className="fal fa-heart"></i>
                    </span>
                  </div>
                </div>
                <div className="bd-teanding__content">
                  <h4 className="bd-product__title mb-2">
                    <Link to={`/shop-details/${item.id}`}>
                      {item?.translations[language]?.name}
                    </Link>
                  </h4>
                  <div className="bd-product__price d-flex justify-content-between align-items-center">
                    <span className="bd-product__vendor">
                      {
                        item?.vendor && 
                        <>
                            <i className="fas fa-store m-2" />{item?.vendor?.name}
                        </>
                      }
                    </span>
                    <span className="bd-product__new-price">
                      {item.price} {constants.currency}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p> {translations?.nav?.noproduct} </p>
  
      )}
    </>
  );
};

export default GridViewProduct;
