import React, { useEffect, useState } from "react";
import { getVendorList } from "../../utils/ApiCalls";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface Vendor {
  id: number;
  name: string;
  photo: string;
  cover: string;
}

const Vendor = () => {
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1)
  const [searchTerm, setSearchTerm] = useState<string>("");
  const navigate = useNavigate()
  const translations = useSelector((state: RootState) => state.user.translations);

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await getVendorList(page, 10 , [{"keywords": searchTerm}]); 
        setVendors(response.data.data); 
        setTotalPages(response.data.meta.last_page);
      } catch (error) {
        console.error("Error fetching vendor data:", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchVendors();
  }, [page,searchTerm]);



  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
      setLoading(true); 
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
      setLoading(true); 
    }
  };

  const handleCardClick = (id: number) => {
    navigate(`/vendor/${id}`); 
  };

  if (loading) {
    return (
        <div className="d-flex justify-content-center align-items-center my-5">
            <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    ) 
        
  }



  return (
    <div className="container">
      {/* <h2 className="text-center my-4">الموردون</h2> */}
      <div className="mb-4 mt-4">
        <input
          style={{height:"45px !important"}}
          type="text"
          className="form-control"
          placeholder={translations.nav.searchforvendor}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term
        />
      </div>
      <div className="row">
        {vendors.map((vendor) => (
          <div key={vendor.id} className="col-lg-2 col-md-4 mb-4"onClick={() => handleCardClick(vendor.id)} // Navigate on card click
          style={{ cursor: "pointer" }} >
            <div className="card h-60">
              <img
                src={vendor.cover}
                className="card-img-top"
                alt={`${vendor.name} cover`}
                style={{ width: "100%", height: "150px", objectFit: "cover" }}
              />
              <div className="card-body text-center ">
                <img
                  src={vendor.photo}
                  className="rounded-circle mb-3"
                  alt={`${vendor.name} photo`}
                  style={{ width: "74px", height: "74px", objectFit: "cover",marginTop:"-50px" }}
                />
                <h4 className="card-title">{vendor.name}</h4>
              </div>
            </div>
          </div>
        ))}
      </div>
            
            <div className="d-flex justify-content-between my-4">
        <button
          className="btn btn-secondary"
          onClick={handlePreviousPage}
          disabled={page === 1} 
        >
          {translations.nav.previous}
        </button>
        <span>Page {page} of {totalPages}</span>
        <button
          className="btn btn-secondary"
          onClick={handleNextPage}
          disabled={page === totalPages}
        >
          {translations.nav.next}
        </button>
      </div>
    </div>
  );
};

export default Vendor;
