"use client";
import useGlobalContext from "../../hooks/use-context";
import { CartProductTypeTwo } from "../../interFace/interFace";
import {
  cart_product,
  update_cart_product,
  remove_cart_product,
} from "../../redux/slices/cartSlice";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

import { Link } from 'react-router-dom';
import React from "react";
import { constants } from "../../utils/constants";
import { useAppDispatch } from '../../hooks/store-hooks';


const SidebarCart = () => {
  const { openCart, setOpenCart } = useGlobalContext();
  const dispatch = useAppDispatch();
  const translations = useSelector((state: RootState) => state.user.translations);
  
  const cartProducts = useSelector(
    (state: RootState) => state.cart.cartProducts
  );
  const totalPrice = useSelector(
    (state: RootState) => state.cart.total
  );

  const language: any = useSelector(
    (state: RootState) => state.user.language
  );
  const updateCartItem = (product: CartProductTypeTwo, quantity: number) => {
    dispatch(update_cart_product(product, quantity));
  };

  const handleDeleteProduct = (product: CartProductTypeTwo) => {
    dispatch(remove_cart_product(product));
  };

  const handleChange = (e: any, product: CartProductTypeTwo) => {
    dispatch(update_cart_product(product, e.target.value));
  };
  return (
    <>
      <div className="fix">
        <div
          className={`sidebar-action sidebar-cart ${
            openCart ? "cart-open" : ""
          }`}
        >
          <div className="cartmini__wrapper">
            <div className="cartmini__title">
              <h4>{translations?.nav?.shoppingcart} </h4>
              
            </div>
            <div className="cartmini__close">
              <button
                type="button"
                className="cartmini__close-btn"
                onClick={() => setOpenCart(false)}
              >
                <i className="fal fa-times"></i>
              </button>
            </div>
            <div className="cartmini__widget">
              {cartProducts.length ? (
                <>
                  <div className="cartmini__inner">
                    <ul>
                      {cartProducts.map((item, index) => {
                        return (
                          <li key={index}>
                            <div className="cartmini__thumb">
                              <Link to={`/shop-details/${item.id}`}>
                                <img
                                  width={50}
                                  height={100}
                                  style={{ width: "70px", height: "auto" }}
                                  src={item.product_photo}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="cartmini__content">
                              <h5>
                                <Link to={`/shop-details/${item.id}`}>
                                  {item?.translations[language]?.name}
                                </Link>
                              </h5>
                              <div className="product-quantity mt-10 mb-10">
                                <span
                                  className="cart-minus"
                                  onClick={() => updateCartItem(item, item.quantity - 1)}
                                >
                                  -
                                </span>
                                <input
                                  className="cart-input"
                                  type="text"
                                  onChange={ (e) => { handleChange(e, item)}}
                                  value={item.quantity}
                                />
                                <span
                                  className="cart-plus"
                                  onClick={() => updateCartItem(item, item.quantity + 1)}
                                >
                                  +
                                </span>
                              </div>
                              <div className="product__sm-price-wrapper">
                                <span className="product__sm-price">
                                  {item.price} {constants.currency}
                                </span>
                              </div>
                            </div>
                            <span
                              className="cartmini__del"
                              onClick={() => handleDeleteProduct(item)}
                            >
                              <i className="fal fa-times"></i>
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="cartmini__checkout">
                    <div className="cartmini__checkout-title mb-30">
                      <h4>{translations?.nav?.total} :</h4>
                      <span className="subtotal-price">{totalPrice} {constants.currency}</span>
                    </div>
                    <div className="cartmini__checkout-btn">
                      <Link onClick={() => setOpenCart(false)} className="bd-fill__btn w-100" to="/cart">
                          {translations?.nav?.viewshoppingcart}
                      </Link>
                      <Link onClick={() => setOpenCart(false)} className="bd-unfill__btn w-100" to="/checkout">
                          {translations?.nav?.checkout}
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p className="text-center pt-20">{translations?.nav?.emptyCart}</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={() => setOpenCart(false)}
        className={`offcanvas-overlay ${openCart ? "overlay-open" : ""}`}
      ></div>
    </>
  );
};

export default SidebarCart;
