"use client"
import EmailIcon from "../../sheardComponent/elements/icons/email-icon";
import LocationIcon from "../../sheardComponent/elements/icons/location-icon";
import PhoneIcon from "../../sheardComponent/elements/icons/phone-icon";
import { useForm, SubmitHandler } from "react-hook-form";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {Helmet} from "react-helmet";

interface FormData {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const translations = useSelector((state: RootState) => state.user.translations);


  const onSubmit: SubmitHandler<FormData> = (data) => {
    const name = data.name;
    const email = data.email;
    const phone = data.phone;
    const message = data.message;

    const userInfo = {
      name,
      email,
      phone,
      message,
  
    };
  };

  return (
    <>
    
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`${translations?.nav?.website} - ${translations?.nav?.contact}`}</title>
        </Helmet>
        <section className="bd-Contact__area pt-105">
          <div className="container">
            <div className="row g-0 justify-content-center">
              <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-12">
                <div className="bd-contact__main-wrapper mb-70">
                  <div className="bd-section__title-wrapper">
                    <h2 className="bd-section__title mb-50">{translations?.nav?.getintouch}</h2>
                  </div>
                  <div className="bd-contact__form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="bd-single__form-input mb-20">
                            <input
                              type="text"
                              placeholder={translations?.nav?.name}
                              {...register("name", {
                                required: "name is required",
                              })}
                            />
                            {errors.name && <span>{errors.name.message}</span>}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="bd-single__form-input  mb-20">
                            <input type="text" placeholder={translations?.nav?.phone} {...register("phone", {
                                required: "phone is required",
                              })} />
                              {errors.phone && <span>{errors.phone.message}</span>}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="bd-single__form-input  mb-20">
                            <input
                              type="text"
                              placeholder={translations?.nav?.email}
                              
                              {...register("email", {
                                required: "Email or UserName is required",
                                pattern: {
                                  value:
                                    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                                  message: "Invalid email format",
                                },
                              })}
                            />
                            {errors.email && <span>{errors.email.message}</span>}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="bd-single__form-input  mb-20">
                            <textarea
                              
                              id="message"
                              placeholder={translations?.nav?.getintouch}
                              {...register("message", {
                                required: "message is required",
                              })}
                            />
                            {errors.message && <span>{errors.message.message}</span>}
                          </div>
                        </div>
                      </div>
                      <div className="contact-btn">
                        <button type="submit" className="bd-fill__btn">
                        {translations?.nav?.sumbit}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-4 col-lg-4">
                <div className="bd__sidebar-wrapper mb-70">
                  <div className="bd-sidebar__support">
                    <div className="bd-sidebar__title">
                      <h4>{translations?.nav?.support}</h4>
                    </div>
                    <div className="bd-sidebar__content">
                      <div className="bd-contact__list">
                        <div className="bd-contact__item">
                          <div className="bd-contact__item-list">
                            <div className="bd-contact__icon">
                              <PhoneIcon />
                            </div>
                            <div className="bd-contact__content">
                              <div className="bd-contact__title">
                                <h4>{translations?.nav?.phone}</h4>
                              </div>
                              <span>
                              {translations?.nav?.number} :{" "}
                                <a href="tel:(+88)872-670-780">
                                  <span>(+88) 872-670-780</span>
                                </a>
                              </span>
                              <span>
                              {translations?.nav?.number} :{" "}
                                <a href="tel:(+8)422-655-793">
                                  <span>(+8) 422-655 -793</span>
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="bd-contact__item">
                          <div className="bd-contact__item-list">
                            <div className="bd-contact__icon">
                              <EmailIcon />
                            </div>
                            <div className="bd-contact__content">
                              <div className="bd-contact__title">
                                <h4>{translations?.nav?.email}</h4>
                              </div>
                              <span>
                                <a href="mailto:Info@example.com">
                                  Info@example.com
                                </a>
                              </span>
                              <span>
                                <a href="mailto:Info@example.com">
                                  Contact@example.com
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="bd-contact__item">
                          <div className="bd-contact__item-list">
                            <div className="bd-contact__icon">
                              <LocationIcon />
                            </div>
                            <div className="bd-contact__content">
                              <div className="bd-contact__title">
                                <h4>{translations?.nav?.location}</h4>
                              </div>
                              <p>
                                Abbot Favicon Kinney, New York, <br />
                                USA - 25423
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  );
};

export default ContactForm;
