import axios from "axios";
import { store } from "../redux/store";

interface HelperParams {
    url: string,
    data?: any,
    signal?: any
}

export const Helper = {
    Get : async (url: string) => {
        const body = {
            headers: {
                Authorization: store.getState().user.user ? 'Bearer ' + store.getState().user?.user?.token : ''
            },
        }
        return axios.get(url, body).then(response => {
            return response; 
        }).catch(error => {
            return error;
        });
    
    },
    Get_Abort : async ({url, data = null, signal}:HelperParams) => {
        const body = {
            headers: {
                Authorization: store.getState().user ? 'Bearer ' + store.getState().user?.user?.token : ''
            },
            params : data ? data : {},
            signal : signal
        }
        return axios.get(url, body).then(response => {
            return response; 
        }).catch(error => {
            return error;
        });
    
    },
    Post: async ({url, data = null} :HelperParams) => {
       
        const body = {
            headers: {
                Authorization: store.getState().user ? 'Bearer ' + store.getState().user?.user?.token : ''
            }
        }
        return axios.post(url, data, body).then(response => {
            return response; 
        }).catch(error => {
            return error;
        });
    
    },
    Put: async({url, data = null} :HelperParams) => {
        
        const body = {
            headers: {
                Authorization: store.getState().user ? 'Bearer ' + store.getState().user?.user?.token : ''
            }
        }
        data['_method'] = 'PUT';
        return axios.post(url, data, body).then(response => {
            return response;
        }).catch(error => {
            return error;
        });

    },
    Delete: async({url, data = null} :HelperParams) => {
        
        const body = {
            headers: {
                Authorization: store.getState().user ? 'Bearer ' + store.getState().user?.user?.token : ''
            },
            data: data
        }
        return axios.delete(url, body).then(response => {
            return response; 
        }).catch(error => {
            return error;
        });

    }
}
