'use client'
import AboutPagetitle from './AboutPagetitle';
import AboutSectionTwo from './AboutSectionTwo';
import AboutTestSlider from './AboutTestSlider';
import React, { useEffect, useState } from "react";
import { getPageList } from "../../utils/ApiCalls";

const AboutMain = () => {
    const [item, setItem] = useState<any>(null);
    const [loading, setLoading] = useState<any>(true);
    useEffect(() => {
    
        getPageList(1,1,[{name: 'about_us'}])
         .then((response) => {
            if(response.data.data.length > 0) setItem(response.data.data[0])
                setLoading(false)
         })
      }, []);
    return (
        <>
            <AboutPagetitle/>
            <AboutSectionTwo item={item} loading={loading} />
            {
                item && item?.sections?.length > 0 && (
                    item?.sections?.map((section: any, key:any) => {
                        return <AboutTestSlider key={key} section={section} sectionIndex={key} />
                    })
                )   
            }
        </>
    );
};

export default AboutMain;