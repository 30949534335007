import { Link } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import getNavMenusList from '../../data/headernav/nav-menus';

const NavMenu = () => {
    
    const translations = useSelector((state: RootState) => state.user.translations);

    const nav_menus_list = getNavMenusList(translations);

    if (!Array.isArray(nav_menus_list)) {
        return <div>Error: Navigation menu list is not an array.</div>;
    }

    
    return (
        <ul>
            {nav_menus_list?.map((item, index) => (
                <li key={index} className={`${item.hasDropdown && !item.megamenu ? 'active has-dropdown' : item.megamenu && 'mega-menu has-dropdown'}`}>
                    <Link to={item.link}>{item.title}</Link>

                    {item.hasDropdown && !item.megamenu && (
                        <ul className="submenu">
                            {item.dropdownItems?.map((menu, subIndex) => (
                                <li key={subIndex}>
                                    <Link to={menu.link}>{menu.title}</Link>
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default NavMenu;