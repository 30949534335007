"use client";
import React from "react";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import "swiper/css/bundle";
import { testimonialList } from "@/data/testimonial-data";

const AboutTestSlider = ({section, sectionIndex}: any) => {
  const language: any = useSelector(
    (state: RootState) => state.user.language
  );
  const description = section?.translations[language].description;
  return (
    <section className={`bd-testimonial__area pt-50 pb-50` + ( sectionIndex % 2 == 0 ? ' grey-bg' : '' )}>
      <div className=" container">
          <div className="row g-0 justify-content-center">
            <div className="col-xxl-10 col-xl-10 col-lg-10">
                <div className="bd-about__content-box mt-0">
                    <div className="bd-section__title-wrapper text-center">
                      <h3 className="bd-section__title mb-30">
                        { section?.translations[language].name }
                      </h3>
                    </div>
                </div>
                <div className={"row" + ( sectionIndex % 2 == 0 ? ' flex-row-reverse' : '' )}>
                    {
                      section?.photo != '' && 
                      (
                        <div className={( description ? 'col-xxl-6 col-xl-6 col-lg-6 ' : '') + "col-sm-12 d-flex align-items-center justify-content-center"}>
                            <img src={section?.photo} alt="about-image" className="img-fluid h-auto" style={{maxHeight:'400px'}} />
                        </div>
                      )
                    }
                    
                    {
                      description && 
                      (
                        <div className={( section?.photo != '' ? 'col-xxl-6 col-xl-6 col-lg-6 ' : '') + "col-sm-12"}>
                            { description }
                        </div>
                      )
                    }

                </div>
            </div>
          </div>
      </div>
    </section>
  );
};

export default AboutTestSlider;
